import { useCallback, useState } from 'react';

export interface UseToggle {
  isOn: boolean;
  toggle: () => void;
  toggleOn: () => void;
  toggleOff: () => void;
}

export const useToggle = (initialValue = false): UseToggle => {
  const [isOn, setIsOn] = useState(initialValue);

  const toggle = useCallback(() => {
    setIsOn(v => !v);
  }, []);
  const toggleOn = useCallback(() => {
    setIsOn(true);
  }, []);
  const toggleOff = useCallback(() => {
    setIsOn(false);
  }, []);

  return { isOn, toggle, toggleOn, toggleOff };
};
