import { RootState } from '../state';
import { UsersState } from './users.model';
import { FeatureName } from 'models/FeatureName';
import { Permission } from 'models/Permission';
import { User } from 'models/User';

const selectUsersState = (state: RootState): UsersState => state.users;

export const getCurrentUserInfo = (state: RootState): User | undefined => selectUsersState(state).current;

export const isCurrentUserAuthenticated = (state: RootState): boolean => getCurrentUserInfo(state) !== undefined;

export const getUserFeatures = (state: RootState): Record<FeatureName, boolean> | undefined =>
  selectUsersState(state).current?.features;
export const isFeatureEnabled =
  (feature: FeatureName) =>
  (state: RootState): boolean =>
    selectUsersState(state).current?.features[feature] ?? false;

export const getUserPermissions = (state: RootState): Permission[] =>
  selectUsersState(state).current?.permissions ?? [];
export const hasPermission =
  (permission: Permission) =>
  (state: RootState): boolean =>
    selectUsersState(state).current?.permissions.includes(permission) ?? false;
