import { map, Observable } from 'rxjs';
import { CancellationBatchResponse } from 'models/Cancellations/CancellationBatchResponse';
import { HttpClient } from 'utils/http/httpClient.models';

export interface CancellationsApi {
  cancelOrdersBatch(exchange: string, orderIds: string[]): Observable<CancellationBatchResponse>;
  getCancellationBatch(exchange: string, cancellationId: string): Observable<CancellationBatchResponse>;
}

export const CancellationsApiConnector = (httpClient: HttpClient): CancellationsApi => ({
  cancelOrdersBatch(exchange: string, orderIds: string[]): Observable<CancellationBatchResponse> {
    return httpClient
      .post<CancellationBatchResponse>({ url: `/api/v1/${exchange}/cancellations`, body: { orderIds } })
      .pipe(map(({ payload }) => payload));
  },
  getCancellationBatch(exchange: string, cancellationId: string): Observable<CancellationBatchResponse> {
    return httpClient
      .getJSON<CancellationBatchResponse>({ url: `/api/v1/${exchange}/cancellations/${cancellationId}` })
      .pipe(map(({ payload }) => payload));
  },
});
