import { Actions } from '../actions';
import {
  POSITIONS_QUERIED,
  POSITIONS_QUERY_FAILED,
  POSITIONS_QUERY_REQUESTED,
  POSITION_DETAILS_FETCHED,
  POSITION_DETAILS_FETCH_FAILED,
  POSITION_SELECTED,
} from './positions.actions';
import { PositionsState, initialPositionsState } from './positions.model';
import { patchObjectWithPatcher } from 'utils/objects/objects';

export const positionsReducer = (state: PositionsState = initialPositionsState, action: Actions): PositionsState => {
  switch (action.type) {
    case POSITIONS_QUERY_REQUESTED:
      return patchObjectWithPatcher(state, () => ({
        queryStatus: 'IN_PROGRESS',
        queryError: undefined,
      }));
    case POSITIONS_QUERIED: {
      return patchObjectWithPatcher(state, _ => ({
        queryStatus: 'SUCCESS',
        results: action.response.data,
      }));
    }
    case POSITIONS_QUERY_FAILED:
      return patchObjectWithPatcher(state, () => ({
        queryStatus: 'FAIL',
        queryError: action.error,
      }));

    case POSITION_SELECTED:
      return patchObjectWithPatcher(state, _ => ({
        selectedPosition: action.position,
        positionDetailsQueryStatus: 'IN_PROGRESS',
        positionDetailsQueryError: undefined,
      }));
    case POSITION_DETAILS_FETCHED: {
      const { entries } = action.details;
      return patchObjectWithPatcher(state, _ => ({
        positionDetails: entries,
        positionDetailsQueryStatus: 'SUCCESS',
      }));
    }
    case POSITION_DETAILS_FETCH_FAILED:
      return patchObjectWithPatcher(state, _ => ({
        selectedPosition: undefined,
        positionDetailsQueryError: action.error,
        positionDetailsQueryStatus: 'FAIL',
      }));

    default:
      return state;
  }
};
