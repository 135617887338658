import { CombinedState, combineReducers, Reducer } from 'redux';
import { Actions } from './actions';
import { cancellationsReducer } from './cancellations/cancellations.reducer';
import { definitionsReducer } from './definitions/definitions.reducer';
import { exchangesReducer } from './exchanges/exchanges.reducer';
import { executionsReducer } from './executions/executions.reducer';
import { filtersReducer } from './filters/filters.reducer';
import { killSwitchReducer } from './killSwitch/killSwitch.reducer';
import { massCancelReducer } from './massCancel/massCancel.reducer';
import { ordersReducer } from './orders/orders.reducer';
import { positionsReducer } from './positions/positions.reducer';
import { queriesReducer } from './queries/queries.reducer';
import { sessionsReducer } from './sessions/sessions.reducer';
import { RootState } from './state';
import { usersReducer } from './users/users.reducer';

export const createRootReducer = (): Reducer<CombinedState<RootState>, Actions> =>
  combineReducers<RootState>({
    queries: queriesReducer,
    definitions: definitionsReducer,
    exchanges: exchangesReducer,
    executions: executionsReducer,
    positions: positionsReducer,
    filters: filtersReducer,
    orders: ordersReducer,
    sessions: sessionsReducer,
    users: usersReducer,
    killSwitch: killSwitchReducer,
    massCancel: massCancelReducer,
    cancellations: cancellationsReducer,
  });
