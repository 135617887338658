import { map, Observable } from 'rxjs';
import { HttpClient } from 'utils/http/httpClient.models';

export interface MassCancelApi {
  massCancel(exchange: string, riskGroupId: number): Observable<Record<string, never>>;
}

export const massCancelApiConnector = (httpClient: HttpClient): MassCancelApi => ({
  massCancel(exchange, riskGroupId) {
    return httpClient
      .post<Record<string, never>>({ url: `/api/v1/${exchange}/do-mass-cancel`, body: { riskGroupId } })
      .pipe(map(({ payload }) => payload));
  },
});
