import { SchedulerLike, throwError, catchError, map, Observable, timeout } from 'rxjs';
import { AjaxError, AjaxResponse } from 'rxjs/ajax';
import { HttpCall, HttpConfig } from './httpClient.models';
import { HttpError } from './httpError';
import { HttpTimeoutError } from './httpTimeoutError';
import { applyResponseErrorMiddleware, applyResponseMiddleware } from './middlewares';

function applyRequestTimeout(config: HttpConfig, scheduler?: SchedulerLike) {
  return function operator<T>(source$: Observable<T>): Observable<T> {
    if (config.timeOut) {
      const timeoutError = new HttpTimeoutError(config.timeOut, config.headers?.['x-correlation-id']);
      return source$.pipe(
        timeout({
          each: config.timeOut,
          with: () => throwError(() => timeoutError),
          scheduler,
        }),
      );
    }
    return source$;
  };
}

function successResponseMapper<T>(ajaxResponse: AjaxResponse<T>, start: number): HttpCall<T> {
  const end = performance.now();
  const payload =
    !ajaxResponse.response || (typeof ajaxResponse.response === 'string' && ajaxResponse.response === '')
      ? (null as unknown as T)
      : ajaxResponse.response;

  return {
    payload,
    headers: ajaxResponse.responseHeaders,
    timeTaken: end - start,
  };
}

export function mapResultWithResponseMiddleware<T>(
  config: HttpConfig,
  start: number,
  scheduler?: SchedulerLike,
): (input$: Observable<AjaxResponse<T>>) => Observable<HttpCall<T>> {
  return (input$: Observable<AjaxResponse<T>>): Observable<HttpCall<T>> =>
    input$.pipe(
      catchError(async (err: AjaxError) => {
        const response = err.responseType === 'blob' ? JSON.parse(await err.response.text()) : err.response;

        applyResponseErrorMiddleware(err);
        if (config.skipErrorMapping) {throw err;}
        const end = performance.now();
        throw new HttpError(
          err.name,
          err.status,
          config.headers?.['x-correlation-id'],
          config.url,
          end - start,
          response,
        );
      }),
      applyRequestTimeout(config, scheduler),
      map(applyResponseMiddleware),
      map(ajaxResponse => successResponseMapper<T>(ajaxResponse, start)),
    );
}

export const getAttachmentFilename = (headers: Record<string, string>): string | undefined => {
  const contentDisposition = headers['content-disposition'];
  if (!contentDisposition) {return undefined;}
  const parts = contentDisposition.replace(/^\r+$/g, '').split('; ');
  const filename = parts.reduce<string | undefined>(
    (result, part) => (part.startsWith('filename=') ? part.replace(/^filename=/, '').trim() : result),
    undefined,
  );
  return filename;
};
