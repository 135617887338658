import { add, format, isValid } from 'date-fns';
import { formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz';

export const DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm:ss.SSS';
export const API_DATE_TIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ssXXX";
export const DEFAULT_START_TIME = new Date(new Date().setHours(6, 0, 0, 0));
export const DEFAULT_END_TIME = new Date(new Date().setHours(5, 59, 59, 999));
export const DEFAULT_LOCAL_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const toDateString = (value: Date | number | undefined, dateFormat = 'yyyy-MM-dd'): string =>
  value === undefined ? '' : format(value, dateFormat);

export const toDateTimeString = (
  value: Date | number | undefined,
  timeZone: string,
  dateTimeFormat = DATE_TIME_FORMAT,
) => {
  if (value === undefined) {return '';}
  try {
    return formatInTimeZone(new Date(value), timeZone, dateTimeFormat);
  } catch (e) {
    return value.toString();
  }
};

export const toApiDateTimeString = (
  value: Date | number | undefined,
  dateTimeFormat = "yyyy-MM-dd'T'HH:mm:ssxxx",
): string => (value === undefined ? '' : format(value, dateTimeFormat));

export const cloneDate = (value: Date | number | undefined): Date | undefined => (value ? new Date(value) : undefined);

export const parseTime = (value: string) => {
  const [, milliseconds] = value.split('.');
  const [hours, minutes, seconds] = value.split(':');
  return new Date(
    new Date().setHours(
      parseInt(hours, 10),
      parseInt(minutes, 10),
      parseInt(seconds, 10),
      parseInt(milliseconds ?? '000', 10),
    ),
  );
};

export const toMarketOpen = (value: Date | number, timeZone = DEFAULT_LOCAL_TIMEZONE) => {
  const utc = zonedTimeToUtc(value, timeZone);
  return isValid(utc) ? utc : zonedTimeToUtc(parseTime('06:00:00.000'), DEFAULT_LOCAL_TIMEZONE);
};

export const toMarketClose = (value: Date | number, offsetDays = 1, timeZone = DEFAULT_LOCAL_TIMEZONE) => {
  const utc = add(zonedTimeToUtc(value, timeZone), { days: offsetDays });
  return isValid(utc)
    ? utc
    : add(zonedTimeToUtc(parseTime('05:59:59.999'), DEFAULT_LOCAL_TIMEZONE), { days: offsetDays });
};

export const isValidTimeZone = (timeZone: string) => {
  try {
    Intl.DateTimeFormat(undefined, { timeZone });
    return true;
  } catch {
    return false;
  }
};
