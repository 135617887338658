import {
  EXCHANGES_DEFINITIONS_FETCHED,
  EXCHANGES_DEFINITIONS_FETCH_FAILED,
  EXCHANGES_DEFINITIONS_REQUESTED,
  EXCHANGES_INFO_FETCHED,
  EXCHANGES_INFO_FETCH_FAILED,
  EXCHANGES_INFO_REQUESTED,
} from './exchanges.actions';
import { ExchangesState, initialExchangesState } from './exchanges.model';
import { Actions } from 'store/actions';
import { patchObjectWithPatcher } from 'utils/objects/objects';

export const exchangesReducer = (state: ExchangesState = initialExchangesState, action: Actions): ExchangesState => {
  switch (action.type) {
    case EXCHANGES_DEFINITIONS_REQUESTED:
      return patchObjectWithPatcher(state, () => ({
        definitions: undefined,
        definitionsFetchError: undefined,
        definitionsFetchStatus: 'IN_PROGRESS',
      }));
    case EXCHANGES_DEFINITIONS_FETCHED:
      return patchObjectWithPatcher(state, () => ({
        definitions: action.definitions,
        definitionsFetchError: undefined,
        definitionsFetchStatus: 'SUCCESS',
      }));
    case EXCHANGES_DEFINITIONS_FETCH_FAILED:
      return patchObjectWithPatcher(state, () => ({
        definitions: undefined,
        definitionsFetchError: action.error,
        definitionsFetchStatus: 'FAIL',
      }));
    case EXCHANGES_INFO_REQUESTED:
      return patchObjectWithPatcher(state, () => ({
        infos: undefined,
        infosFetchError: undefined,
        infosFetchStatus: 'IN_PROGRESS',
      }));
    case EXCHANGES_INFO_FETCHED:
      return patchObjectWithPatcher(state, () => ({
        infos: action.exchangesInfo,
        infosFetchError: undefined,
        infosFetchStatus: 'SUCCESS',
      }));
    case EXCHANGES_INFO_FETCH_FAILED:
      return patchObjectWithPatcher(state, () => ({
        infos: undefined,
        infosFetchError: action.error,
        infosFetchStatus: 'FAIL',
      }));
    default:
      return state;
  }
};
