import React from 'react';
import { defineCustomElements } from '@sg-bootstrap/components/loader';
import { createRoot } from 'react-dom/client';
import { initializeAgGrid } from './AgGrid';
import { App } from './App';
import { initializeMatomo } from './Matomo';
import reportWebVitals from './reportWebVitals';
import { sgwtConnect } from './SgConnect';
import { initializeTheme } from './theme';
import { store } from 'store';
import { themeInitialized } from 'store/sessions/sessions.actions';
import { currentUserInfoRequested } from 'store/users/users.actions';

import './polyfill';
import './styles.scss';

initializeMatomo();
initializeAgGrid();
defineCustomElements(window);

if (sgwtConnect.isAuthorized()) {
  const theme = initializeTheme();
  store.dispatch(currentUserInfoRequested());
  store.dispatch(themeInitialized(theme));

  const container = document.getElementById('root');
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const root = createRoot(container!);
  root.render(<App />);
} else if (sgwtConnect.getAuthorizationError()) {
  // Handle the error here
} else {
  sgwtConnect.requestAuthorization();
}

reportWebVitals();
