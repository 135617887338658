import { DcErrorResponse } from 'models/Responses/DcErrorResponse';
import { FilterOptionsResponse } from 'models/Responses/FilterOptionsResponse';

export type FiltersActions =
  | FilterProductsFetched
  | FilterProductsFetchFailed
  | FilterCustomersFetched
  | FilterCustomersFetchFailed
  | FilterTradingSystemsFetched
  | FilterTradingSystemsFetchFailed
  | FilterCustomerSystemsFetched
  | FilterCustomerSystemsFetchFailed
  | FilterUserSystemsFetched
  | FilterUserSystemsFetchFailed;

export const FILTER_PRODUCTS_FETCHED = '[Filters API] Filter Products fetched';
export const filterProductsFetched = (exchange: string, response: FilterOptionsResponse) =>
  ({ type: FILTER_PRODUCTS_FETCHED, exchange, response } as const);
export type FilterProductsFetched = ReturnType<typeof filterProductsFetched>;

export const FILTER_PRODUCTS_FETCH_FAILED = '[Filters API] Filter Products fetch failed';
export const filterProductsFetchFailed = (exchange: string, error: DcErrorResponse) =>
  ({ type: FILTER_PRODUCTS_FETCH_FAILED, exchange, error } as const);
export type FilterProductsFetchFailed = ReturnType<typeof filterProductsFetchFailed>;

export const FILTER_CUSTOMERS_FETCHED = '[Filters API] Filter Customers fetched';
export const filterCustomersFetched = (exchange: string, response: FilterOptionsResponse) =>
  ({ type: FILTER_CUSTOMERS_FETCHED, exchange, response } as const);
export type FilterCustomersFetched = ReturnType<typeof filterCustomersFetched>;

export const FILTER_CUSTOMERS_FETCH_FAILED = '[Filters API] Filter Customers fetch failed';
export const filterCustomersFetchFailed = (exchange: string, error: DcErrorResponse) =>
  ({ type: FILTER_CUSTOMERS_FETCH_FAILED, exchange, error } as const);
export type FilterCustomersFetchFailed = ReturnType<typeof filterCustomersFetchFailed>;

export const FILTER_TRADING_SYSTEMS_FETCHED = '[Filters API] Filter Trading Systems fetched';
export const filterTradingSystemsFetched = (exchange: string, customer: string, response: FilterOptionsResponse) =>
  ({ type: FILTER_TRADING_SYSTEMS_FETCHED, exchange, customer, response } as const);
export type FilterTradingSystemsFetched = ReturnType<typeof filterTradingSystemsFetched>;

export const FILTER_TRADING_SYSTEMS_FETCH_FAILED = '[Filters API] Filter Trading Systems fetch failed';
export const filterTradingSystemsFetchFailed = (exchange: string, customer: string, error: DcErrorResponse) =>
  ({ type: FILTER_TRADING_SYSTEMS_FETCH_FAILED, exchange, customer, error } as const);
export type FilterTradingSystemsFetchFailed = ReturnType<typeof filterTradingSystemsFetchFailed>;

export const FILTER_CUSTOMER_SYSTEMS_FETCHED = '[Filters API] Filter Customer Systems fetched';
export const filterCustomerSystemsFetched = (
  exchange: string,
  customer: string,
  tradingSystem: string,
  response: FilterOptionsResponse,
) => ({ type: FILTER_CUSTOMER_SYSTEMS_FETCHED, exchange, customer, tradingSystem, response } as const);
export type FilterCustomerSystemsFetched = ReturnType<typeof filterCustomerSystemsFetched>;

export const FILTER_CUSTOMER_SYSTEMS_FETCH_FAILED = '[Filters API] Filter Customer Systems fetch failed';
export const filterCustomerSystemsFetchFailed = (
  exchange: string,
  customer: string,
  tradingSystem: string,
  error: DcErrorResponse,
) => ({ type: FILTER_CUSTOMER_SYSTEMS_FETCH_FAILED, exchange, customer, tradingSystem, error } as const);
export type FilterCustomerSystemsFetchFailed = ReturnType<typeof filterCustomerSystemsFetchFailed>;

export const FILTER_USER_SYSTEMS_FETCHED = '[Filters API] Filter User Systems fetched';
export const filterUserSystemsFetched = (
  exchange: string,
  customer: string,
  tradingSystem: string,
  customerSystemId: string,
  response: FilterOptionsResponse,
) => ({ type: FILTER_USER_SYSTEMS_FETCHED, exchange, customer, tradingSystem, customerSystemId, response } as const);
export type FilterUserSystemsFetched = ReturnType<typeof filterUserSystemsFetched>;

export const FILTER_USER_SYSTEMS_FETCH_FAILED = '[Filters API] Filter User Systems fetch failed';
export const filterUserSystemsFetchFailed = (
  exchange: string,
  customer: string,
  tradingSystem: string,
  customerSystemId: string,
  error: DcErrorResponse,
) => ({ type: FILTER_USER_SYSTEMS_FETCH_FAILED, exchange, customer, tradingSystem, customerSystemId, error } as const);
export type FilterUserSystemsFetchFailed = ReturnType<typeof filterUserSystemsFetchFailed>;
