import { Actions } from '../actions';
import {
  CANCEL_ORDERS_BATCH_INIT_FAILED,
  CANCEL_ORDERS_BATCH_INITIALIZED,
  CANCEL_ORDERS_BATCH_UPDATED,
  CANCEL_ORDERS_INIT_REQUESTED,
  CANCEL_ORDERS_RESET,
} from './cancellations.actions';
import { CancellationsState, initialCancellationsState } from './cancellations.model';
import { patchObjectWithPatcher } from 'utils/objects/objects';

export const cancellationsReducer = (
  state: CancellationsState = initialCancellationsState,
  action: Actions,
): CancellationsState => {
  switch (action.type) {
    case CANCEL_ORDERS_INIT_REQUESTED:
      return patchObjectWithPatcher(state, _ => ({
        cancellationId: undefined,
        status: undefined,
        exchange: undefined,
        orders: undefined,
      }));
    case CANCEL_ORDERS_BATCH_INITIALIZED:
      return patchObjectWithPatcher(state, _ => ({
        cancellationId: action.cancelBatch.id,
        status: action.cancelBatch.status,
        exchange: action.exchange,
        orders: action.cancelBatch.orders,
      }));
    case CANCEL_ORDERS_BATCH_INIT_FAILED:
      return patchObjectWithPatcher(state, _ => ({
        error: action.error,
      }));
    case CANCEL_ORDERS_BATCH_UPDATED:
      return patchObjectWithPatcher(state, _ => ({
        orders: action.cancelBatch.orders,
        status: action.cancelBatch.status,
      }));
    case CANCEL_ORDERS_RESET:
      return patchObjectWithPatcher(state, _ => ({
        cancellationId: undefined,
        status: undefined,
        exchange: undefined,
        error: undefined,
        orders: undefined,
      }));
    default:
      return state;
  }
};
