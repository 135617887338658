export interface LocalStorageApi {
  get<T extends string>(key: LocalStorageKey): T | undefined;
  set(key: LocalStorageKey, value: string): void;
  remove(key: LocalStorageKey): void;
  clear(): void;
}

export type LocalStorageKey = 'APP_THEME' | 'APP_TIMEZONE_TYPE';

export const localStorageApi: LocalStorageApi = {
  get<T extends string>(key: LocalStorageKey): T | undefined {
    return (window.localStorage.getItem(key) as T | null) ?? undefined;
  },
  set(key: LocalStorageKey, value: string): void {
    window.localStorage.setItem(key, value);
  },
  remove(key: LocalStorageKey): void {
    window.localStorage.removeItem(key);
  },
  clear(): void {
    window.localStorage.clear();
  },
};
