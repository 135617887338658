import { applyMiddleware, createStore, PreloadedState, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createEpicMiddleware } from 'redux-observable';
import thunk from 'redux-thunk';
import { Actions } from './actions';
import { DcEpicDependencies, rootEpics } from './epics';
import { createRootReducer } from './reducer';
import { RootState } from './state';

export const configureStore = (state: DeepPartial<RootState>, withDevTools: boolean): Store<RootState, Actions> => {
  const epicMiddleware = createEpicMiddleware<Actions, Actions, RootState, DcEpicDependencies>();

  const enhancers = applyMiddleware(thunk, epicMiddleware);

  const store = createStore<RootState, Actions, unknown, unknown>(
    createRootReducer(),
    state as PreloadedState<RootState>,
    withDevTools ? composeWithDevTools(enhancers) : enhancers,
  );

  epicMiddleware.run(rootEpics());

  return store;
};
