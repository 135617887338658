import { Permission } from 'models/Permission';
import { UserFeatures } from 'models/UserFeatures';
import { AppRoute, getAppRoutes } from 'routes/index';

export const getRouteByPath = (path: string): AppRoute | undefined => {
  const routes = getAppRoutes();
  return routes.find(r => (r.exactPath ? r.path === path : path.startsWith(r.path)));
};

export const isAuthorizedToRoute = (
  route: AppRoute,
  permissions: Permission[] | undefined,
  features: UserFeatures | undefined,
): boolean => {
  const areRequiredFeaturesEnabled =
    route.featureRequirements?.reduce(
      (acc, f) => acc && (features !== undefined && features[f] !== undefined ? features[f] : false),
      true,
    ) ?? true;

  const haveRequiredPermissions =
    route.permissionRequirements?.reduce((acc, p) => acc && (permissions?.includes(p) ?? false), true) ?? true;

  return areRequiredFeaturesEnabled && haveRequiredPermissions;
};

const getUnauthorizedPage = (): string => {
  const {
    sgwtServices: { unauthorizedPage },
  } = window.appConfigs;
  return unauthorizedPage;
};

export const getUnauthorizedRedirectUrl = (): string => {
  const unauthorizedPage = getUnauthorizedPage();
  const origin = encodeURIComponent(window.location.origin);
  return `${unauthorizedPage}?redirectUri=${origin}&language=en`;
};
