import { localStorageApi } from 'api/localStorage';

const standardStylesheetSelector = 'link#sg-bootstrap-standard';
const standardAgGridStylesheetSelector = 'link#sg-bootstrap-ag-grid-standard';
const darkStylesheetSelector = 'link#sg-bootstrap-dark';
const darkAgGridStylesheetSelector = 'link#sg-bootstrap-ag-grid-dark';

const updateStylesheets = (target: Element, other: Element): void => {
  target.setAttribute('rel', 'stylesheet');
  other.setAttribute('rel', 'preload');
};

const applyDarkTheme = (): void => {
  const targetSgBs = document.querySelector(darkStylesheetSelector);
  const otherSgBs = document.querySelector(standardStylesheetSelector);
  const targetSgBsAgGrid = document.querySelector(darkAgGridStylesheetSelector);
  const otherSgBsAgGrid = document.querySelector(standardAgGridStylesheetSelector);
  if (!targetSgBs || !otherSgBs) {return;}
  updateStylesheets(targetSgBs, otherSgBs);
  if (!targetSgBsAgGrid || !otherSgBsAgGrid) {return;}
  updateStylesheets(targetSgBsAgGrid, otherSgBsAgGrid);
};

const applyStandardTheme = (): void => {
  const targetSgBs = document.querySelector(standardStylesheetSelector);
  const otherSgBs = document.querySelector(darkStylesheetSelector);
  const targetSgBsAgGrid = document.querySelector(standardAgGridStylesheetSelector);
  const otherSgBsAgGrid = document.querySelector(darkAgGridStylesheetSelector);
  if (!targetSgBs || !otherSgBs) {return;}
  updateStylesheets(targetSgBs, otherSgBs);
  if (!targetSgBsAgGrid || !otherSgBsAgGrid) {return;}
  updateStylesheets(targetSgBsAgGrid, otherSgBsAgGrid);
};

export type SgBootstrapTheme = 'DARK' | 'STANDARD';
export const applySgBootstrapTheme = (theme: SgBootstrapTheme | undefined): void => {
  if (theme === 'DARK') {applyDarkTheme();}
  else {applyStandardTheme();}
};

export const initializeTheme = (): SgBootstrapTheme | undefined => {
  const preferredTheme = localStorageApi.get<SgBootstrapTheme>('APP_THEME');
  applySgBootstrapTheme(preferredTheme);
  return preferredTheme;
};
