import { combineEpics, Epic, ofType } from 'redux-observable';
import { switchMap, catchError, of, map, withLatestFrom } from 'rxjs';
import { exchangesInfoFetched, exchangesInfoFetchFailed } from './exchanges.actions';
import { ExchangesApi, exchangesApiConnector } from 'api/exchanges';
import { DcErrorResponse } from 'models/Responses/DcErrorResponse';
import { CURRENT_USER_INFO_FETCHED } from 'store/users/users.actions';
import { httpClient } from 'utils/http/httpClient';

const exchangesApi$ = exchangesApiConnector(httpClient);

export const exchangesInfoRequestedEpic =
  (api$: ExchangesApi): Epic =>
  (actions$, state$) =>
    actions$.pipe(
      ofType(CURRENT_USER_INFO_FETCHED),
      withLatestFrom(state$),
      switchMap(() =>
        api$.getInfo().pipe(
          map(res => exchangesInfoFetched(res)),
          catchError((err: DcErrorResponse) => of(exchangesInfoFetchFailed(err))),
        ),
      ),
    );

export const exchangesEpics = (): Epic => combineEpics(exchangesInfoRequestedEpic(exchangesApi$));
