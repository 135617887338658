import { KILL_SWITCH_FAILED, KILL_SWITCH_REQUESTED, KILL_SWITCH_SUCCEED } from './killSwitch.actions';
import { KillSwitchState, initialKillSwitchState } from './killSwitch.model';
import { Actions } from 'store/actions';

export const killSwitchReducer = (
  state: KillSwitchState = initialKillSwitchState,
  action: Actions,
): KillSwitchState => {
  switch (action.type) {
    case KILL_SWITCH_REQUESTED:
      return { ...state, killSwitchStatus: 'IN_PROGRESS', killSwitchError: undefined };
    case KILL_SWITCH_SUCCEED:
      return { ...state, killSwitchStatus: 'SUCCESS', killSwitchError: undefined };
    case KILL_SWITCH_FAILED:
      return { ...state, killSwitchStatus: 'FAIL', killSwitchError: action.error };
    default:
      return state;
  }
};
