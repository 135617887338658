import { RootState } from '../state';
import { DefinitionsState } from './definitions.model';
import { DefinitionType, Definition } from 'models/Definitions';
import { DcErrorResponse } from 'models/Responses/DcErrorResponse';
import { isRequestStatusFail, isRequestStatusInProgress } from 'utils/requests/requestStatus';

const selectDefinitionsState = (state: RootState): DefinitionsState => state.definitions;

export const getDefinition =
  (type: DefinitionType) =>
  (state: RootState): Definition | undefined =>
    selectDefinitionsState(state)[type].definition;

export const isDefinitionFetchInProgress =
  (type: DefinitionType) =>
  (state: RootState): boolean =>
    isRequestStatusInProgress(selectDefinitionsState(state)[type].definitionFetchStatus);

export const isDefinitionFetchFailed =
  (type: DefinitionType) =>
  (state: RootState): boolean =>
    isRequestStatusFail(selectDefinitionsState(state)[type].definitionFetchStatus);

export const getDefinitionError =
  (type: DefinitionType) =>
  (state: RootState): DcErrorResponse | undefined =>
    selectDefinitionsState(state)[type].definitionFetchError;
