import { combineEpics, Epic, ofType } from 'redux-observable';
import { switchMap, catchError, map, of, mergeMap, EMPTY, ignoreElements, tap } from 'rxjs';
import { DcErrorResponse } from '../../models/Responses/DcErrorResponse';
import {
  ORDERS_QUERY_REQUESTED,
  OrdersQueryRequested,
  ordersQueried,
  ordersQueryFailed,
  ORDERS_CSV_REQUESTED,
  OrdersCsvRequested,
  ordersCsvExported,
  ordersCsvExportFailed,
  ORDER_DETAILS_REQUESTED,
  orderDetailsFetched,
  orderDetailsFetchFailed,
  OrderDetailsRequested,
  CANCEL_ORDER_REQUESTED,
  CancelOrderRequested,
  CANCEL_ORDER_REQUESTED_NEXT,
  CancelOrderRequestedNext,
} from './orders.actions';
import { ordersApiConnector, OrdersApi } from 'api/orders';
import { toast } from 'toast';
import { downloadBlob } from 'utils/blob/blob';
import { httpClient } from 'utils/http/httpClient';

const ordersApi$ = ordersApiConnector(httpClient);

export const ordersQueryRequestedEpic =
  (api$: OrdersApi): Epic =>
  actions$ =>
    actions$.pipe(
      ofType(ORDERS_QUERY_REQUESTED),
      switchMap(({ exchange, page, size, filters }: OrdersQueryRequested) =>
        api$.query(exchange, page, size, filters).pipe(
          map(ordersQueried),
          catchError((err: DcErrorResponse) => of(ordersQueryFailed(err))),
        ),
      ),
    );

export const ordersCsvRequestedEpic =
  (api$: OrdersApi): Epic =>
  actions$ =>
    actions$.pipe(
      ofType(ORDERS_CSV_REQUESTED),
      switchMap(({ exchange, translatedHeaders, filters }: OrdersCsvRequested) =>
        api$.export(exchange, filters).pipe(
          tap(async ({ filename, payload }) => {
            const blobWithHeaders = new Blob([translatedHeaders.join(',').concat('\n'), payload]);
            downloadBlob(blobWithHeaders, filename);
          }),
          map(ordersCsvExported),
          catchError((err: DcErrorResponse) => of(ordersCsvExportFailed(err))),
        ),
      ),
    );

export const orderDetailsRequestedEpic =
  (api$: OrdersApi): Epic =>
  actions$ =>
    actions$.pipe(
      ofType(ORDER_DETAILS_REQUESTED),
      switchMap(({ exchange, id }: OrderDetailsRequested) =>
        api$.getDetails(exchange, id, 0, 1000).pipe(
          map(orderDetailsFetched),
          catchError((err: DcErrorResponse) => of(orderDetailsFetchFailed(err))),
        ),
      ),
    );

export const cancelOrderRequestedEpic =
  (api$: OrdersApi): Epic =>
  actions$ =>
    actions$.pipe(
      ofType(CANCEL_ORDER_REQUESTED),
      mergeMap(({ exchange, exchangeOrderId }: CancelOrderRequested) =>
        api$.cancelOrder(exchange, exchangeOrderId).pipe(
          tap(() =>
            toast.success({
              header: 'toast.cancel-order-initialized-success.header',
              message: 'toast.cancel-order-initialized-success.message',
            }),
          ),
          ignoreElements(),
          catchError(() => {
            toast.danger({
              header: 'toast.cancel-order-initialized-failure.header',
              message: 'toast.cancel-order-initialized-failure.message',
            });
            return EMPTY;
          }),
        ),
      ),
    );

export const cancelOrderRequestedNextEpic =
  (api$: OrdersApi): Epic =>
  actions$ =>
    actions$.pipe(
      ofType(CANCEL_ORDER_REQUESTED_NEXT),
      mergeMap(({ exchange, exchangeOrderId, orderSide, passingCode }: CancelOrderRequestedNext) =>
        api$.cancelOrderNext(exchange, exchangeOrderId, orderSide, passingCode).pipe(
          tap(() =>
            toast.success({
              header: 'toast.cancel-order-initialized-success.header',
              message: 'toast.cancel-order-initialized-success.message',
            }),
          ),
          ignoreElements(),
          catchError(() => {
            toast.danger({
              header: 'toast.cancel-order-initialized-failure.header',
              message: 'toast.cancel-order-initialized-failure.message',
            });
            return EMPTY;
          }),
        ),
      ),
    );

export const ordersEpics = (): Epic =>
  combineEpics(
    ordersQueryRequestedEpic(ordersApi$),
    ordersCsvRequestedEpic(ordersApi$),
    orderDetailsRequestedEpic(ordersApi$),
    cancelOrderRequestedEpic(ordersApi$),
    cancelOrderRequestedNextEpic(ordersApi$),
  );
