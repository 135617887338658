import React from 'react';
import { useAppConfigs } from 'hooks/useAppConfigs/useAppConfigs';

export const SgwtSplashScreen: React.FC = () => {
  const {
    sgwtServices: { applicationId },
  } = useAppConfigs();

  return (
    <sgwt-splash-screen
      application-id={applicationId}
      id="sgm-dropcopy-splash-screen"
      sg-connect-support="sg-connect-v2"
    />
  );
};
