import React, { useMemo } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { I18nMessage } from 'components/i18n/I18nMessage/I18nMessage';
import { getRoutesForHeaderNav } from 'routes';
import { getUserFeatures, getUserPermissions } from 'store/users/users.selectors';
import { isAuthorizedToRoute } from 'utils/route/route';

interface AppNavbarProps {
  className?: string;
}

export const AppNavbar: React.FC<AppNavbarProps> = ({ className }) => {
  const permissions = useSelector(getUserPermissions);
  const features = useSelector(getUserFeatures);
  const { pathname } = useLocation();

  const routes = useMemo(
    () => getRoutesForHeaderNav().filter(route => isAuthorizedToRoute(route, permissions, features)),
    [permissions, features],
  );
  return (
    <ul className={className} data-bs-popper="static">
      {routes.map(r => (
        <li key={r.id} className="navbar-item">
          <Link
            className={classnames('navbar-link', { active: pathname === r.path })}
            to={r.path}
            data-e2e={`header-nav-${r.id}`}
          >
            <I18nMessage id={r.headerNavLabel} />
          </Link>
        </li>
      ))}
    </ul>
  );
};
