import { map, Observable } from 'rxjs';
import { HttpClient } from 'utils/http/httpClient.models';

export interface KillSwitchApi {
  killSwitch(exchange: string, brokerId: string): Observable<Record<string, never>>;
}

export const killSwitchApiConnector = (httpClient: HttpClient): KillSwitchApi => ({
  killSwitch(exchange, brokerId) {
    return httpClient
      .post<Record<string, never>>({ url: `/api/v1/${exchange}/do-kill-switch`, body: { brokerId } })
      .pipe(map(({ payload }) => payload));
  },
});
