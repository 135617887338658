import deepEqual from 'fast-deep-equal';

export const patchObject = <T, K extends keyof T>(obj: T, key: K, value: T[K]): T =>
  deepEqual(obj[key], value)
    ? obj
    : {
        ...obj,
        [key]: value,
      };

export const patchObjectWithPatcher = <T>(obj: T, patcher: Patcher<T>): T => {
  const patch = patcher(obj);
  return Object.keys(patch).length === 0 ? obj : { ...obj, ...patch };
};

export const omitProperties = <T extends Record<string, unknown>, K extends keyof T>(obj: T, keys: K[]): Omit<T, K> =>
  Object.entries(obj)
    .filter(([key]) => !keys.includes(key as K))
    .reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: value,
      }),
      {} as T,
    );

export const pickProperties = <T extends Record<string, unknown>, K extends keyof T>(obj: T, keys: K[]): Pick<T, K> =>
  Object.entries(obj)
    .filter(([key]) => keys.includes(key as K))
    .reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: value,
      }),
      {} as T,
    );

export const isObject = (x: unknown): x is Record<string, unknown> =>
  typeof x === 'object' && !(x instanceof Date) && x !== null;

export const filterObject = <T extends Record<string, unknown>, K extends keyof T>(
  obj: T,
  predicate: (i: T[K]) => boolean,
): Partial<T> =>
  (Object.entries(obj) as [K, T[K]][]).reduce<Partial<T>>(
    (result, [key, value]) => (predicate(value) ? patchObject(result, key, value) : result),
    {},
  );
