import { ApiRequestStatus } from 'models/ApiRequestStatus';
import { Exchange } from 'models/Exchange';
import { ExchangeInfo } from 'models/ExchangeInfo';
import { DcErrorResponse } from 'models/Responses/DcErrorResponse';

export interface ExchangesState {
  definitions?: Exchange[];
  definitionsFetchError?: DcErrorResponse;
  definitionsFetchStatus?: ApiRequestStatus;
  infos?: ExchangeInfo[];
  infosFetchError?: DcErrorResponse;
  infosFetchStatus?: ApiRequestStatus;
}

export const initialExchangesState: ExchangesState = {
  definitions: undefined,
  definitionsFetchError: undefined,
  definitionsFetchStatus: undefined,
  infos: [],
  infosFetchError: undefined,
  infosFetchStatus: undefined,
};
