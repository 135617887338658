import React, { MouseEvent, useCallback } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { AppNavbar } from '../AppNavbar/AppNavbar';
import { SgLogo } from '../SgLogo/SgLogo';
import { I18nMessage } from 'components/i18n/I18nMessage/I18nMessage';
import { SgwtAccountCenter } from 'components/sgwtWidgets/SgwtAccountCenter/SgwtAccountCenter';
import { useToggle } from 'hooks/useToggle/useToggle';

export const AppHeader: React.FC = () => {
  const { isOn, toggle } = useToggle();

  const onMenuToggled = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      toggle();
    },
    [toggle],
  );

  return (
    <header>
      <nav className="navbar navbar-sm border-bottom border-top">
        <div className="navbar-title">
          <Link to="/" className="navbar-title-link">
            <div className="navbar-logo">
              <SgLogo />
            </div>
            <div className="navbar-title-divider" />
            <div className="navbar-service-name">
              <I18nMessage id="app.logo.text" />
            </div>
          </Link>
          <button
            className="navbar-menu-btn btn btn-flat btn-xl btn-icon flex-center"
            type="button"
            data-bs-toggle="dropdown"
            data-bs-offset="0,4"
            aria-expanded="true"
            onClick={onMenuToggled}
          >
            <svg
              className="icon"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="2" y="6" width="20" height="2" fill="currentColor" />
              <rect x="2" y="11" width="20" height="2" fill="currentColor" />
              <rect x="2" y="16" width="20" height="2" fill="currentColor" />
            </svg>
          </button>
          <AppNavbar className={classNames('navbar-menu-dropdown dropdown-menu w-100', { show: isOn })} />
        </div>
        <div className="navbar-content">
          <AppNavbar className="navbar-navigation" />
        </div>

        <div className="navbar-toolbar">
          <SgwtAccountCenter />
        </div>
      </nav>
    </header>
  );
};
