import { Order } from 'models/Orders/Order';
import { OrderSide } from 'models/Orders/OrderSide';
import { QueryFilters } from 'models/QueryFilters';
import { DcErrorResponse } from 'models/Responses/DcErrorResponse';
import { PagedResponse } from 'models/Responses/PagedResponse';

export type OrdersActions =
  | OrdersQueryRequested
  | OrdersQueried
  | OrdersQueryFailed
  | OrdersCsvRequested
  | OrdersCsvExported
  | OrdersCsvExportFailed
  | OrdersSelected
  | OrderDetailsCleared
  | OrderDetailsRequested
  | OrderDetailsFetched
  | OrderDetailsFetchFailed
  | CancelOrderRequested
  | CancelOrderRequestedNext;

export const ORDERS_QUERY_REQUESTED = '[Orders API] Query requested';
export const ordersQueryRequested = (exchange: string, page: number, size: number, filters: QueryFilters) =>
  ({ type: ORDERS_QUERY_REQUESTED, exchange, page, size, filters } as const);
export type OrdersQueryRequested = ReturnType<typeof ordersQueryRequested>;

export const ORDERS_QUERIED = '[Orders API] Queried';
export const ordersQueried = (response: PagedResponse<Order>) => ({ type: ORDERS_QUERIED, response } as const);
export type OrdersQueried = ReturnType<typeof ordersQueried>;

export const ORDERS_QUERY_FAILED = '[Orders API] Query failed';
export const ordersQueryFailed = (error: DcErrorResponse) => ({ type: ORDERS_QUERY_FAILED, error } as const);
export type OrdersQueryFailed = ReturnType<typeof ordersQueryFailed>;

export const ORDERS_CSV_REQUESTED = '[Orders API] CSV requested';
export const ordersCsvRequested = (exchange: string, translatedHeaders: string[], filters: QueryFilters) =>
  ({ type: ORDERS_CSV_REQUESTED, exchange, translatedHeaders, filters } as const);
export type OrdersCsvRequested = ReturnType<typeof ordersCsvRequested>;

export const ORDERS_CSV_EXPORTED = '[Orders API] CSV exported';
export const ordersCsvExported = () => ({ type: ORDERS_CSV_EXPORTED } as const);
export type OrdersCsvExported = ReturnType<typeof ordersCsvExported>;

export const ORDERS_CSV_EXPORT_FAILED = '[Orders API] CSV export failed';
export const ordersCsvExportFailed = (error: DcErrorResponse) => ({ type: ORDERS_CSV_EXPORT_FAILED, error } as const);
export type OrdersCsvExportFailed = ReturnType<typeof ordersCsvExportFailed>;

export const ORDERS_SELECTED = '[Orders page] Orders selected';
export const ordersSelected = (ids: string[]) => ({ type: ORDERS_SELECTED, ids } as const);
export type OrdersSelected = ReturnType<typeof ordersSelected>;

export const ORDER_DETAILS_CLEARED = '[Orders page] Order details cleared';
export const orderDetailsCleared = (id: string) => ({ type: ORDER_DETAILS_CLEARED, id } as const);
export type OrderDetailsCleared = ReturnType<typeof orderDetailsCleared>;

export const ORDER_DETAILS_REQUESTED = '[Orders API] Order details requested';
export const orderDetailsRequested = (exchange: string, id: string) =>
  ({ type: ORDER_DETAILS_REQUESTED, exchange, id } as const);
export type OrderDetailsRequested = ReturnType<typeof orderDetailsRequested>;

export const ORDER_DETAILS_FETCHED = '[Orders API] Order details fetched';
export const orderDetailsFetched = (details: PagedResponse<Order>) =>
  ({ type: ORDER_DETAILS_FETCHED, details } as const);
export type OrderDetailsFetched = ReturnType<typeof orderDetailsFetched>;

export const ORDER_DETAILS_FETCH_FAILED = '[Orders API] Order details fetch failed';
export const orderDetailsFetchFailed = (error: DcErrorResponse) =>
  ({ type: ORDER_DETAILS_FETCH_FAILED, error } as const);
export type OrderDetailsFetchFailed = ReturnType<typeof orderDetailsFetchFailed>;

export const CANCEL_ORDER_REQUESTED = '[Orders API] Cancel order requested';
export const cancelOrderRequested = (exchange: string, exchangeOrderId: string) =>
  ({ type: CANCEL_ORDER_REQUESTED, exchange, exchangeOrderId } as const);
export type CancelOrderRequested = ReturnType<typeof cancelOrderRequested>;

export const CANCEL_ORDER_REQUESTED_NEXT = '[Orders API] Cancel order requested Next';
export const cancelOrderRequestedNext = (
  exchange: string,
  exchangeOrderId: string,
  orderSide: OrderSide,
  passingCode: string,
) => ({ type: CANCEL_ORDER_REQUESTED_NEXT, exchange, exchangeOrderId, orderSide, passingCode } as const);
export type CancelOrderRequestedNext = ReturnType<typeof cancelOrderRequestedNext>;
