import { combineEpics, Epic, ofType } from 'redux-observable';
import { mergeMap, catchError, tap, map, of } from 'rxjs';
import { MassCancelRequested, MASS_CANCEL_REQUESTED, massCancelSucceed, massCancelFailed } from './massCancel.actions';
import { MassCancelApi, massCancelApiConnector } from 'api/massCancel';
import { DcErrorResponse } from 'models/Responses/DcErrorResponse';
import { toast } from 'toast';
import { httpClient } from 'utils/http/httpClient';

const massCancelApi$ = massCancelApiConnector(httpClient);

export const massCancelRequestedEpic =
  (api$: MassCancelApi): Epic =>
  actions$ =>
    actions$.pipe(
      ofType(MASS_CANCEL_REQUESTED),
      tap(() =>
        toast.info({
          header: 'toast.mass-cancel.requested.header',
          message: 'toast.mass-cancel.requested.message',
        }),
      ),
      mergeMap(({ exchange, riskGroupId }: MassCancelRequested) =>
        api$.massCancel(exchange, riskGroupId).pipe(
          tap(() => {
            toast.clearAll();
            toast.success({
              header: 'toast.mass-cancel.success.header',
              message: 'toast.mass-cancel.success.message',
            });
          }),
          map(massCancelSucceed),
          catchError((e: DcErrorResponse) => {
            toast.clearAll();

            if (e.status === 403) {
              toast.danger({
                header: 'toast.mass-cancel.fail.403.header',
                message: 'toast.mass-cancel.fail.403.message',
              });
            } else {
              toast.danger({
                header: 'toast.mass-cancel.fail.header',
                message: 'toast.mass-cancel.fail.message',
              });
            }

            return of(massCancelFailed(e));
          }),
        ),
      ),
    );

export const massCancelEpics = (): Epic => combineEpics(massCancelRequestedEpic(massCancelApi$));
