import { Actions } from '../actions';
import { DEFINITION_FETCHED, DEFINITION_FETCH_FAILED, DEFINITION_REQUESTED } from './definitions.actions';
import { DefinitionsState, initialDefinitionsState } from './definitions.model';

export const definitionsReducer = (
  state: DefinitionsState = initialDefinitionsState,
  action: Actions,
): DefinitionsState => {
  switch (action.type) {
    case DEFINITION_REQUESTED:
      return {
        ...state,
        [action.definitionType]: { definitionFetchStatus: 'IN_PROGRESS' },
      };
    case DEFINITION_FETCHED:
      return {
        ...state,
        [action.definitionType]: {
          definitionFetchStatus: 'SUCCESS',
          definition: action.definition,
        },
      };
    case DEFINITION_FETCH_FAILED:
      return {
        ...state,
        [action.definitionType]: {
          definitionFetchStatus: 'FAIL',
          definitionFetchError: action.error,
        },
      };
    default:
      return state;
  }
};
