import React from 'react';
import { ToastsContainer } from '../ToastsContainer/ToastsContainer';
import { SessionExpiredModal } from 'components/modals/SessionExpiredModal/SessionExpiredModal';
import { SgwtHelpCenter } from 'components/sgwtWidgets/SgwtHelpCenter/SgwtHelpCenter';
import { SgwtSplashScreen } from 'components/sgwtWidgets/SgwtSplashScreen/SgwtSplashScreen';

export const AppWidgets: React.FC = () => (
  <>
    <SgwtSplashScreen />
    <SgwtHelpCenter />
    <SessionExpiredModal />
    <ToastsContainer />
  </>
);
