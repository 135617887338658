import { RootState } from '../state';
import { SessionsState } from './sessions.model';
import { Locale } from 'intl/translations';
import { SgBootstrapTheme } from 'theme';

const selectSessionsState = (state: RootState): SessionsState => state.sessions;

export const isSgConnectSessionExpired = (state: RootState): boolean => selectSessionsState(state).isExpired;
export const getCurrentLocale = (state: RootState): Locale => selectSessionsState(state).locale;
export const getCurrentTheme = (state: RootState): SgBootstrapTheme => selectSessionsState(state).theme ?? 'STANDARD';
export const getCurrentTimeZoneType = (state: RootState) => selectSessionsState(state).timeZoneType;
