import React from 'react';
import { FormattedMessage } from 'react-intl';
import { I18nValues, defaultI18nValues } from 'hooks/useI18n/useI18n';
import { I18nKeys } from 'intl/i18nKeys';

export interface I18nMessageProps {
  id: I18nKeys;
  values?: I18nValues;
}

export const I18nMessage: React.FC<I18nMessageProps> = ({ id, values = {} }) => (
  <FormattedMessage id={id} values={{ ...defaultI18nValues, ...values }} />
);
