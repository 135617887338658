import React from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { I18nMessage, I18nMessageProps } from 'components/i18n/I18nMessage/I18nMessage';
import { I18nKeys } from 'intl/i18nKeys';
import { DcErrorResponse } from 'models/Responses/DcErrorResponse';
import { getCurrentUserInfo } from 'store/users/users.selectors';

export interface GenericErrorMessageProps {
  className?: string;
  e2e?: string;
  error?: DcErrorResponse;
  subject?: I18nKeys;
  messages?: I18nMessageProps[];
}

export const GenericErrorMessage: React.FC<GenericErrorMessageProps> = ({
  className,
  e2e,
  subject = 'unexpectedError.title',
  messages = [{ id: 'unexpectedError.message.generic' }],
  error,
}) => {
  const user = useSelector(getCurrentUserInfo);
  return (
    <div className="h-100 d-flex align-items-center justify-content-center">
      <div
        style={{ maxWidth: 650 }}
        className={classnames(
          'd-flex flex-column align-items-center justify-content-center text-center text-danger',
          className,
        )}
        data-e2e={e2e}
      >
        <em className="icon icon-xl">error_outline</em>
        <h4 className="fw-semibold mb-3">
          <I18nMessage id={subject} />
        </h4>
        {messages.map(({ id: msg, values }, i) => (
          <p className={classnames('line-height-1 mb-0', i !== 0 && 'mt-2')} data-e2e={`${e2e}-message`} key={msg}>
            <I18nMessage id={msg} values={values} />
          </p>
        ))}
        {error && (
          <p className={classnames('line-height-1 mb-0 mt-2')} data-e2e={`${e2e}-message`}>
            <I18nMessage id="unexpectedError.message.correlationId" values={{ correlationId: error.correlationId }} />
          </p>
        )}
        {error && user && user.isSgGroupUser && (
          <div className="text-center mt-4">
            <p className="mb-0">
              <span className="badge badge-discreet-danger fw-semibold m-1" data-e2e={`${e2e}-status-code`}>
                <I18nMessage id="http.statusCode" values={{ statusCode: error.status }} />
              </span>
            </p>
            <div className="mt-1 mb-0" data-e2e={`${e2e}-error-codes`}>
              {error.response?.errors.map(err => (
                <div key={err.code} className="d-flex flex-column align-items-center">
                  <span className="badge badge-outline-danger badge-sm m-1" data-e2e={`${e2e}-error-code`}>
                    {err.code}
                  </span>
                  <code className="mt-1">{err.message}</code>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
