import { Actions } from '../actions';
import {
  EXECUTIONS_CSV_EXPORTED,
  EXECUTIONS_CSV_EXPORT_FAILED,
  EXECUTIONS_CSV_REQUESTED,
  EXECUTIONS_QUERIED,
  EXECUTIONS_QUERY_FAILED,
  EXECUTIONS_QUERY_REQUESTED,
} from './executions.actions';
import { ExecutionsState, initialExecutionsState } from './executions.model';
import { patchObjectWithPatcher, patchObject } from 'utils/objects/objects';

export const executionsReducer = (
  state: ExecutionsState = initialExecutionsState,
  action: Actions,
): ExecutionsState => {
  switch (action.type) {
    case EXECUTIONS_QUERY_REQUESTED:
      return patchObjectWithPatcher(state, () => ({
        queryStatus: 'IN_PROGRESS',
        queryError: undefined,
      }));
    case EXECUTIONS_QUERIED: {
      const { entries, ...pagination } = action.response;
      return patchObjectWithPatcher(state, _ => ({
        pagination,
        queryStatus: 'SUCCESS',
        results: entries,
      }));
    }
    case EXECUTIONS_QUERY_FAILED:
      return patchObjectWithPatcher(state, () => ({
        queryStatus: 'FAIL',
        queryError: action.error,
      }));
    case EXECUTIONS_CSV_REQUESTED:
      return patchObjectWithPatcher(state, () => ({
        exportStatus: 'IN_PROGRESS',
        exportError: undefined,
      }));
    case EXECUTIONS_CSV_EXPORTED:
      return patchObject(state, 'exportStatus', 'SUCCESS');
    case EXECUTIONS_CSV_EXPORT_FAILED:
      return patchObjectWithPatcher(state, () => ({
        exportStatus: 'FAIL',
        exportError: action.error,
      }));
    default:
      return state;
  }
};
