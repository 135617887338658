import React from 'react';
import { useI18nMessage } from 'hooks/useI18n/useI18n';

export const SgLogo: React.FC = () => {
  const altText = useI18nMessage('img.alt.sgLogo');

  return (
    <img
      src="https://sgwt-cdn-shared.sgmarkets.com/assets/images/socgen_logo_muted.svg"
      height="32"
      alt={altText}
      className="d-block"
    />
  );
};
