import { QueryType } from 'models/Queries';
import { QueryFilters } from 'models/QueryFilters';
import { DcError } from 'models/Responses/DcErrorResponse';
import { parseTime, toMarketClose, toMarketOpen } from 'utils/dates/dates';

export type QueriesState = Record<QueryType, QueryState>;

export interface QueryState {
  selectedFilters: QueryFilters;
  appliedFilters: QueryFilters;
  pageSize: number;
  isFilterOn: boolean;
  queryError?: DcError;
  urlParams?: string;
}

const MARKET_OPEN_TIME = '06:00:00.000';
const MARKET_CLOSE_TIME = '05:59:59.999';

export const initialQueriesState: QueriesState = {
  orders: {
    appliedFilters: {},
    selectedFilters: {
      businessDates: {
        from: toMarketOpen(parseTime(MARKET_OPEN_TIME)),
        to: toMarketClose(parseTime(MARKET_CLOSE_TIME)),
      },
    },
    pageSize: window.appConfigs.defaultPageSize,
    isFilterOn: true,
  },
  executions: {
    appliedFilters: {},
    selectedFilters: {
      businessDates: {
        from: toMarketOpen(parseTime(MARKET_OPEN_TIME)),
        to: toMarketClose(parseTime(MARKET_CLOSE_TIME)),
      },
    },
    pageSize: window.appConfigs.defaultPageSize,
    isFilterOn: true,
  },
  positions: {
    appliedFilters: {},
    selectedFilters: {
      businessDates: {
        from: toMarketOpen(parseTime(MARKET_OPEN_TIME)),
        to: toMarketClose(parseTime(MARKET_CLOSE_TIME)),
      },
    },
    pageSize: 0,
    isFilterOn: true,
  },
};
