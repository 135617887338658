import React, { useCallback } from 'react';
import classnames from 'classnames';
import { useObservable } from 'react-use-observable';
import styled from 'styled-components';
import { I18nMessage } from 'components/i18n/I18nMessage/I18nMessage';
import { Toast, toast } from 'toast';

const StyledDiv = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: calc(100vw - 360px - 1.5rem);
  z-index: 1100;
  margin-top: 6rem;
`;

// TODO: Use 'observable-hooks' instead
export const ToastsContainer: React.FC = () => {
  const [toasts] = useObservable<Toast[]>(() => toast.getToastsSubject(), []);

  const dismissToast = useCallback(
    (id: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      toast.dismiss(id);
    },
    [],
  );

  return !toasts ? null : (
    <StyledDiv data-e2e="toasts-container">
      {toasts.map(t => (
        <div
          className={classnames(`toast toast-${t.style} fade`, 'show')}
          data-e2e={`toast-${t.id}`}
          id={`toast-${t.id}`}
          key={t.id}
          role="alert"
        >
          {t.header && (
            <div className="d-flex toast-header">
              <strong>
                <I18nMessage id={t.header} />
              </strong>
              <span className="flex-grow-1" />
              <button
                aria-label="Close"
                className="btn-close"
                data-dismiss="toast"
                data-e2e={`toast-${t.id}-dismiss`}
                id={`toast-${t.id}-dismiss`}
                onClick={dismissToast(t.id)}
                type="button"
              />
            </div>
          )}
          <div className="toast-body">
            <I18nMessage id={t.message} />
          </div>
          {t.footer && (
            <div className="toast-footer">
              <span className="text-secondary fw-medium">
                <I18nMessage id={t.footer} />
              </span>
            </div>
          )}
        </div>
      ))}
    </StyledDiv>
  );
};
