import { Actions } from '../actions';
import {
  ORDERS_CSV_EXPORTED,
  ORDERS_CSV_EXPORT_FAILED,
  ORDERS_CSV_REQUESTED,
  ORDERS_QUERIED,
  ORDERS_QUERY_FAILED,
  ORDERS_QUERY_REQUESTED,
  ORDERS_SELECTED,
  ORDER_DETAILS_CLEARED,
  ORDER_DETAILS_FETCHED,
  ORDER_DETAILS_FETCH_FAILED,
  ORDER_DETAILS_REQUESTED,
} from './orders.actions';
import { OrdersState, initialOrdersState } from './orders.model';
import { patchObjectWithPatcher, patchObject } from 'utils/objects/objects';

export const ordersReducer = (state: OrdersState = initialOrdersState, action: Actions): OrdersState => {
  switch (action.type) {
    case ORDERS_QUERY_REQUESTED:
      return patchObjectWithPatcher(state, _ => ({
        pagination: undefined,
        queryError: undefined,
        queryStatus: 'IN_PROGRESS',
        results: undefined,
        selectedOrderDetails: undefined,
        selectedOrderDetailsPagination: undefined,
        selectedOrderDetailsQueryError: undefined,
        selectedOrderDetailsQueryStatus: undefined,
        selectedOrderIds: [],
      }));
    case ORDERS_QUERIED: {
      const { entries, ...pagination } = action.response;
      return patchObjectWithPatcher(state, _ => ({
        pagination,
        queryStatus: 'SUCCESS',
        results: entries,
      }));
    }
    case ORDERS_QUERY_FAILED:
      return patchObjectWithPatcher(state, () => ({
        queryStatus: 'FAIL',
        queryError: action.error,
      }));
    case ORDERS_CSV_REQUESTED:
      return patchObjectWithPatcher(state, () => ({
        exportStatus: 'IN_PROGRESS',
        exportError: undefined,
      }));
    case ORDERS_CSV_EXPORTED:
      return patchObject(state, 'exportStatus', 'SUCCESS');
    case ORDERS_CSV_EXPORT_FAILED:
      return patchObjectWithPatcher(state, () => ({
        exportStatus: 'FAIL',
        exportError: action.error,
      }));
    case ORDERS_SELECTED:
      return patchObject(state, 'selectedOrderIds', action.ids);
    case ORDER_DETAILS_CLEARED:
      return patchObject(state, 'selectedOrderDetails', undefined);
    case ORDER_DETAILS_REQUESTED:
      return patchObjectWithPatcher(state, _ => ({
        selectedOrderDetails: undefined,
        selectedOrderDetailsPagination: undefined,
        selectedOrderDetailsQueryStatus: 'IN_PROGRESS',
        selectedOrderDetailsQueryError: undefined,
      }));
    case ORDER_DETAILS_FETCHED: {
      const { entries, ...paginationDetails } = action.details;
      return patchObjectWithPatcher(state, _ => ({
        selectedOrderDetails: entries,
        selectedOrderDetailsPagination: paginationDetails,
        selectedOrderDetailsQueryStatus: 'SUCCESS',
      }));
    }
    case ORDER_DETAILS_FETCH_FAILED:
      return patchObjectWithPatcher(state, _ => ({
        selectedOrderDetails: undefined,
        selectedOrderDetailsPagination: undefined,
        selectedOrderDetailsQueryError: action.error,
        selectedOrderDetailsQueryStatus: 'FAIL',
      }));
    default:
      return state;
  }
};
