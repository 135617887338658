import { PreloadedState } from 'redux';
import { CancellationsState, initialCancellationsState } from './cancellations/cancellations.model';
import { DefinitionsState, initialDefinitionsState } from './definitions/definitions.model';
import { ExchangesState, initialExchangesState } from './exchanges/exchanges.model';
import { ExecutionsState, initialExecutionsState } from './executions/executions.model';
import { FiltersState, initialFiltersState } from './filters/filters.model';
import { KillSwitchState, initialKillSwitchState } from './killSwitch/killSwitch.model';
import { MassCancelState, initialMassCancelState } from './massCancel/massCancel.model';
import { OrdersState, initialOrdersState } from './orders/orders.model';
import { PositionsState, initialPositionsState } from './positions/positions.model';
import { QueriesState, initialQueriesState } from './queries/queries.model';
import { SessionsState, initialSessionsState } from './sessions/sessions.model';
import { initialUsersState, UsersState } from './users/users.model';

export interface RootState {
  queries: QueriesState;
  definitions: DefinitionsState;
  exchanges: ExchangesState;
  executions: ExecutionsState;
  positions: PositionsState;
  filters: FiltersState;
  orders: OrdersState;
  sessions: SessionsState;
  users: UsersState;
  killSwitch: KillSwitchState;
  massCancel: MassCancelState;
  cancellations: CancellationsState;
}

export const initialState: PreloadedState<RootState> = {
  queries: initialQueriesState,
  definitions: initialDefinitionsState,
  exchanges: initialExchangesState,
  executions: initialExecutionsState,
  positions: initialPositionsState,
  filters: initialFiltersState,
  orders: initialOrdersState,
  sessions: initialSessionsState,
  users: initialUsersState,
  killSwitch: initialKillSwitchState,
  massCancel: initialMassCancelState,
  cancellations: initialCancellationsState,
};
