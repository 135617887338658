import { CancellationBatchStatus } from 'models/Cancellations/CancellationBatchStatus';
import { OrderCancel } from 'models/Cancellations/OrderCancel';
import { DcErrorResponse } from 'models/Responses/DcErrorResponse';

export interface CancellationsState {
  cancellationId?: string;
  status?: CancellationBatchStatus;
  orders?: OrderCancel[];
  exchange?: string;
  error?: DcErrorResponse;
}

export const initialCancellationsState: CancellationsState = {
  cancellationId: undefined,
  status: undefined,
  exchange: undefined,
  error: undefined,
  orders: undefined,
};
