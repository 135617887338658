import React, { lazy } from 'react';
import { I18nKeys } from 'intl/i18nKeys';
import { FeatureName } from 'models/FeatureName';
import { Permission } from 'models/Permission';

const ExecutionsRoute = lazy(() => import('./Executions/Executions.route'));
const PositionRoute = lazy(() => import('./Position/Position.route'));
const OrdersRoute = lazy(() => import('./Orders/Orders.route'));
const AdminRoute = lazy(() => import('./Admin/Admin.route'));
const UnauthorizedRoute = lazy(() => import('./Unauthorized/Unauthorized.route'));

export interface AppRoute {
  id: string;
  component: React.FC;
  exactPath?: boolean;
  headerNavLabel?: I18nKeys;
  path: string;
  featureRequirements?: FeatureName[];
  permissionRequirements?: Permission[];
}

export type AppHeaderNavRoute = AppRoute & { headerNavLabel: I18nKeys };

const appRoutes: AppRoute[] = [
  {
    id: 'orders',
    component: OrdersRoute,
    exactPath: true,
    headerNavLabel: 'header.nav.orders',
    path: '/orders',
    permissionRequirements: ['VIEW_ORDERS'],
  },
  {
    id: 'executions',
    component: ExecutionsRoute,
    exactPath: true,
    headerNavLabel: 'header.nav.executions',
    path: '/executions',
    permissionRequirements: ['VIEW_EXECUTIONS'],
  },
  {
    id: 'positions',
    component: PositionRoute,
    exactPath: true,
    headerNavLabel: 'header.nav.position',
    path: '/position',
    permissionRequirements: ['VIEW_CLIENT_POSITION'],
  },
  {
    id: 'admin',
    component: AdminRoute,
    exactPath: true,
    headerNavLabel: 'header.nav.admin',
    path: '/admin',
    permissionRequirements: ['VIEW_ADMIN'],
  },
  {
    id: 'unauthorized',
    component: UnauthorizedRoute,
    exactPath: true,
    path: '/unauthorized',
  },
];

export const getAppRoutes = (): AppRoute[] => appRoutes;

export const getRoutesForHeaderNav = (): AppHeaderNavRoute[] =>
  appRoutes.filter(r => r.headerNavLabel !== undefined) as AppHeaderNavRoute[];
