import React from 'react';
import { Provider } from 'react-redux';
import { AppIntlProvider } from '../AppIntlProvider/AppIntlProvider';
import { AppRouter } from '../AppRouter/AppRouter';
import { store } from 'store';

export const Main: React.FC = () => (
  <Provider store={store}>
    <AppIntlProvider>
      <AppRouter />
    </AppIntlProvider>
  </Provider>
);
