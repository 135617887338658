import { Thunk } from '../actions';
import {
  cancelOrdersBatchInitRequested,
  cancelOrdersBatchUpdated,
  cancelOrdersStopRequested,
} from './cancellations.actions';
import { CancellationBatchResponse } from 'models/Cancellations/CancellationBatchResponse';
import { isFeatureEnabled } from 'store/selectors';

export const cancelSelectedOrdersRequestedThunk =
  (exchange: string, selectedOrderIds: string[]): Thunk<void> =>
  (dispatch, getState) => {
    const state = getState();
    const isOrderCancellationNextEnabled = isFeatureEnabled('OrderCancellationNext')(state);
    if (!isOrderCancellationNextEnabled) {
      return;
    }

    dispatch(cancelOrdersBatchInitRequested(exchange, selectedOrderIds));
  };

export const cancelOrdersBatchUpdatedThunk =
  (exchange: string, cancelResponse: CancellationBatchResponse): Thunk<void> =>
  (dispatch, _) => {
    dispatch(cancelOrdersBatchUpdated(exchange, cancelResponse));

    const cancellationStatus = cancelResponse.status;
    if (cancellationStatus === 'DONE') {
      dispatch(cancelOrdersStopRequested());
    }
  };
