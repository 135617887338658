import { combineEpics, Epic, ofType } from 'redux-observable';
import { map, switchMap, catchError, of } from 'rxjs';
import { CURRENT_USER_INFO_REQUESTED } from './users.actions';
import { currentUserInfoFetchedThunk, currentUserInfoFetchFailedThunk } from './users.thunks';
import { UsersApi, usersApiConnector } from 'api/users';
import { DcErrorResponse } from 'models/Responses/DcErrorResponse';
import { httpClient } from 'utils/http/httpClient';

const usersApi$ = usersApiConnector(httpClient);

export const currentUserInfoRequestedEpic =
  (api$: UsersApi): Epic =>
    actions$ =>
      actions$.pipe(
        ofType(CURRENT_USER_INFO_REQUESTED),
        switchMap(() =>
          api$.getCurrentUserInfo().pipe(
            map(user => currentUserInfoFetchedThunk(user)),
            catchError((error: DcErrorResponse) => of(currentUserInfoFetchFailedThunk(error))),
          ),
        ),
      );

export const usersEpics = (): Epic => combineEpics(currentUserInfoRequestedEpic(usersApi$));
