import { CancellationBatchResponse } from 'models/Cancellations/CancellationBatchResponse';
import { DcErrorResponse } from 'models/Responses/DcErrorResponse';

export type CancellationsActions =
  | CancelOrdersBatchInitRequested
  | CancelOrdersBatchInitialized
  | CancelOrdersBatchInitFailed
  | CancelOrdersBatchUpdated
  | CancelOrdersStopRequested
  | CancelOrdersReset;

export const CANCEL_ORDERS_INIT_REQUESTED = '[Cancellations API] Cancel orders batch initialization requested';
export const cancelOrdersBatchInitRequested = (exchange: string, orderIds: string[]) =>
  ({ type: CANCEL_ORDERS_INIT_REQUESTED, exchange, orderIds } as const);
export type CancelOrdersBatchInitRequested = ReturnType<typeof cancelOrdersBatchInitRequested>;

export const CANCEL_ORDERS_BATCH_INITIALIZED = '[Cancellations API] Cancel orders batch initialized';
export const cancelOrdersBatchInitialized = (exchange: string, cancelBatch: CancellationBatchResponse) =>
  ({ type: CANCEL_ORDERS_BATCH_INITIALIZED, exchange, cancelBatch } as const);
export type CancelOrdersBatchInitialized = ReturnType<typeof cancelOrdersBatchInitialized>;

export const CANCEL_ORDERS_BATCH_INIT_FAILED = '[Cancellations API] Cancel orders batch initialization failed';
export const cancelOrdersBatchInitFailed = (error: DcErrorResponse) =>
  ({ type: CANCEL_ORDERS_BATCH_INIT_FAILED, error } as const);
export type CancelOrdersBatchInitFailed = ReturnType<typeof cancelOrdersBatchInitFailed>;

export const CANCEL_ORDERS_BATCH_UPDATED = '[Cancellations API] Cancel orders batch updated';
export const cancelOrdersBatchUpdated = (exchange: string, cancelBatch: CancellationBatchResponse) =>
  ({ type: CANCEL_ORDERS_BATCH_UPDATED, exchange, cancelBatch } as const);
export type CancelOrdersBatchUpdated = ReturnType<typeof cancelOrdersBatchUpdated>;

export const CANCEL_ORDERS_STOP_REQUESTED = '[Cancellations API] Cancel orders stop requested';
export const cancelOrdersStopRequested = () => ({ type: CANCEL_ORDERS_STOP_REQUESTED } as const);
export type CancelOrdersStopRequested = ReturnType<typeof cancelOrdersStopRequested>;

export const CANCEL_ORDERS_RESET = '[Cancellations API] Cancel orders reset';
export const cancelOrdersReset = () => ({ type: CANCEL_ORDERS_RESET } as const);
export type CancelOrdersReset = ReturnType<typeof cancelOrdersReset>;
