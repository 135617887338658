import { combineEpics, Epic, ofType } from 'redux-observable';
import { switchMap, catchError, map, of, withLatestFrom, EMPTY } from 'rxjs';
import { DcErrorResponse } from '../../models/Responses/DcErrorResponse';
import {
  POSITIONS_QUERY_REQUESTED,
  POSITION_SELECTED,
  PositionSelected,
  PositionsQueryRequested,
  positionDetailsFetchFailed,
  positionDetailsFetched,
  positionsQueried,
  positionsQueryFailed,
} from './positions.actions';
import { OrdersApi, ordersApiConnector } from 'api/orders';
import { positionsApiConnector, PositionsApi } from 'api/positions';
import { QueryFilters } from 'models/QueryFilters';
import { getAppliedFilters } from 'store/selectors';
import { RootState } from 'store/state';
import { httpClient } from 'utils/http/httpClient';

const positionsApi$ = positionsApiConnector(httpClient);
const ordersApi$ = ordersApiConnector(httpClient);

export const positionsQueryRequestedEpic =
  (api$: PositionsApi): Epic =>
  actions$ =>
    actions$.pipe(
      ofType(POSITIONS_QUERY_REQUESTED),
      switchMap(({ exchange, filters }: PositionsQueryRequested) =>
        api$.query(exchange, filters).pipe(
          map(positionsQueried),
          catchError((err: DcErrorResponse) => of(positionsQueryFailed(err))),
        ),
      ),
    );

export const positionDetailsRequestedEpic =
  (api$: OrdersApi): Epic =>
  (actions$, state$) =>
    actions$.pipe(
      ofType(POSITION_SELECTED),
      withLatestFrom<PositionSelected, [RootState]>(state$),
      switchMap(([{ position }, state]) => {
        const filters = getAppliedFilters('positions')(state);
        if (!filters.exchange) {return EMPTY;}

        const { customer, contractSymbol, status } = position;
        let query: QueryFilters = {
          ...filters,
          customer,
          product: contractSymbol,
        };
        if (status !== undefined)
          {query = { ...query, functionalStatus: status === 'Working' ? ['Working', 'Partial filled'] : [status] };}

        return api$.query(filters.exchange, 0, 1000, query).pipe(
          map(positionDetailsFetched),
          catchError((err: DcErrorResponse) => of(positionDetailsFetchFailed(err))),
        );
      }),
    );

export const positionsEpics = (): Epic =>
  combineEpics(positionsQueryRequestedEpic(positionsApi$), positionDetailsRequestedEpic(ordersApi$));
