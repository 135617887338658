import { DcErrorResponse } from 'models/Responses/DcErrorResponse';

export type MassCancelActions = MassCancelRequested | massCancelSucceed | massCancelFailed;

export const MASS_CANCEL_REQUESTED = '[Mass Cancel API] Mass Cancel requested';
export const massCancelRequested = (exchange: string, riskGroupId: number) =>
  ({ type: MASS_CANCEL_REQUESTED, exchange, riskGroupId } as const);
export type MassCancelRequested = ReturnType<typeof massCancelRequested>;

export const MASS_CANCEL_SUCCEED = '[mass Cancel API] Mass Switch succeed';
export const massCancelSucceed = () => ({ type: MASS_CANCEL_SUCCEED } as const);
export type massCancelSucceed = ReturnType<typeof massCancelSucceed>;

export const MASS_CANCEL_FAILED = '[mass Cancel API] Mass Switch failed';
export const massCancelFailed = (error: DcErrorResponse) => ({ type: MASS_CANCEL_FAILED, error } as const);
export type massCancelFailed = ReturnType<typeof massCancelFailed>;
