import { I18nKeys } from './i18nKeys';
import enTranslations from 'locales/en.json';
import frTranslations from 'locales/fr.json';
import { filterObject } from 'utils/objects/objects';

export type Locale = 'en' | 'fr';

export type Translations = Record<I18nKeys, string> & {
  'app.logo.text': 'Drop Copy';
  'app.name': 'SG Markets Drop Copy';
};

type TranslationsPerLocale = Record<Locale, Partial<Translations>> & {
  en: Translations;
};

const translations: TranslationsPerLocale = {
  en: enTranslations as Translations,
  fr: frTranslations as Translations,
};

export const DEFAULT_LOCALE: Locale = 'en';

export const getTranslations = (locale: Locale): Translations => ({
  ...translations[DEFAULT_LOCALE],
  ...filterObject(translations[locale], t => t !== ''),
});
