import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAppConfigs } from 'hooks/useAppConfigs/useAppConfigs';
import { useI18nMessages } from 'hooks/useI18n/useI18n';
import { getCurrentLocale } from 'store/sessions/sessions.selectors';
import { getCurrentUserInfo } from 'store/users/users.selectors';

export const SgwtHelpCenter: React.FC = () => {
  const {
    environment: { name: envName },
    sgwtServices: { applicationId },
  } = useAppConfigs();

  const user = useSelector(getCurrentUserInfo);
  const lang = useSelector(getCurrentLocale);
  const topics = useI18nMessages(['helpCenter.message.topics.unauthorizedPageAccess']);
  const messageTopics = useMemo(() => JSON.stringify(topics.map(t => ({ topic: t }))), [topics]);

  const envNamePrefix = useMemo(() => (envName !== 'PROD' ? `[${envName}]` : ''), [envName]);
  const emailSubject = useMemo(
    () => `${envNamePrefix}[DropCopy][HelpCenter] Message from ${user?.email}`,
    [envNamePrefix, user],
  );

  return (
    <sgwt-help-center
      allow-screenshot="true"
      application-id={applicationId}
      id="sgm-dropcopy-help-center"
      language={lang}
      mail-subject={emailSubject}
      no-improvement="true"
      sg-connect-support="sg-connect-v2"
      message-topics={messageTopics}
    />
  );
};

export const openContactUsForm = (): void => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const helpCenter = document.querySelector('sgwt-help-center#sgm-dropcopy-help-center') as any;
  if (helpCenter) {helpCenter.form();}
};
