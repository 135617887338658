import { BehaviorSubject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { I18nKeys } from 'intl/i18nKeys';
import { BootstrapColor } from 'models/Bootstrap/BootstrapColor';

export type ToastStyle = BootstrapColor;

export interface Toast {
  id: string;
  style: ToastStyle;
  header?: I18nKeys;
  message: I18nKeys;
  footer?: I18nKeys;
}

export interface ToastService {
  clearAll: () => void;
  danger: (toast: Omit<Toast, 'id' | 'style'>) => void;
  dismiss: (id: string) => void;
  getToasts: () => Toast[];
  getToastsSubject: () => BehaviorSubject<Array<Toast>>;
  info: (toast: Omit<Toast, 'id' | 'style'>) => void;
  success: (toast: Omit<Toast, 'id' | 'style'>) => void;
  toasts: BehaviorSubject<Array<Toast>>;
  warning: (toast: Omit<Toast, 'id' | 'style'>) => void;
}

export const toast = ((defaultTimeout: number): ToastService => {
  const toasts = new BehaviorSubject<Array<Toast>>([]);

  const getToasts = (): Toast[] => toasts.getValue();
  const getToastsSubject = (): BehaviorSubject<Array<Toast>> => toasts;

  const clearAll = (): void => toasts.next([]);
  const dismiss = (id: string): void => {
    toasts.next(toasts.getValue().filter(t => t.id !== id));
  };
  const add = (t: Omit<Toast, 'id'>): void => {
    const id = uuidv4();
    toasts.next([...toasts.getValue(), { ...t, id }]);

    setTimeout(() => dismiss(id), defaultTimeout);
  };

  const info = (t: Omit<Toast, 'id' | 'style'>): void => add({ ...t, style: 'info' });
  const warning = (t: Omit<Toast, 'id' | 'style'>): void => add({ ...t, style: 'warning' });
  const danger = (t: Omit<Toast, 'id' | 'style'>): void => add({ ...t, style: 'danger' });
  const success = (t: Omit<Toast, 'id' | 'style'>): void => add({ ...t, style: 'success' });

  return {
    clearAll,
    danger,
    dismiss,
    getToasts,
    getToastsSubject,
    info,
    success,
    toasts,
    warning,
  };
})(window.appConfigs.defaultToastTimeout);
