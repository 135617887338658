import { ApiRequestStatus } from 'models/ApiRequestStatus';
import { DcErrorResponse } from 'models/Responses/DcErrorResponse';

export interface KillSwitchState {
  killSwitchStatus?: ApiRequestStatus;
  killSwitchError?: DcErrorResponse;
}

export const initialKillSwitchState: KillSwitchState = {
  killSwitchError: undefined,
  killSwitchStatus: undefined,
};
