import { kebabCase } from 'lodash';
import { map, Observable } from 'rxjs';
import { DefinitionType, Definition } from 'models/Definitions';
import { HttpClient } from 'utils/http/httpClient.models';

export interface DefinitionsApi {
  getDefinition(type: DefinitionType): Observable<Definition>;
}

export const definitionsApiConnector = (httpClient: HttpClient): DefinitionsApi => ({
  getDefinition(type: DefinitionType): Observable<Definition> {
    return httpClient
      .getJSON<Definition>({ url: `/api/v1/definitions/${kebabCase(type)}` })
      .pipe(map(({ payload }) => payload));
  },
});
