import { map, Observable } from 'rxjs';
import { User } from 'models/User';
import { HttpClient } from 'utils/http/httpClient.models';

export interface UsersApi {
  getCurrentUserInfo(): Observable<User>;
}

export const usersApiConnector = (httpClient: HttpClient): UsersApi => ({
  getCurrentUserInfo(): Observable<User> {
    return httpClient.getJSON<User>({ url: '/api/v1/users/me' }).pipe(map(({ payload }) => payload));
  },
});
