import { Thunk } from '../actions';
import { getCurrentLocale } from '../sessions/sessions.selectors';
import { RootState } from '../state';
import { currentUserInfoFetched, currentUserInfoFetchFailed } from './users.actions';
import { getTranslations } from 'intl/translations';
import { trackUserInMatomo } from 'Matomo';
import { DcErrorResponse } from 'models/Responses/DcErrorResponse';
import { User } from 'models/User';

const getUnauthorizedPage = (): string => {
  const {
    sgwtServices: { unauthorizedPage },
  } = window.appConfigs;
  return unauthorizedPage;
};

const getMaintenancePage = (): string => {
  const {
    sgwtServices: { maintenancePage },
  } = window.appConfigs;
  return maintenancePage;
};

const getContactUsEmail = (): string => {
  const { contactUsEmail } = window.appConfigs;
  return contactUsEmail;
};

const getUnauthorizedRedirectUrl = (state: RootState): string => {
  const unauthorizedPage = getUnauthorizedPage();
  const lang = encodeURIComponent(getCurrentLocale(state));
  const origin = encodeURIComponent(window.location.origin);
  return `${unauthorizedPage}?redirectUri=${origin}&language=${lang}`;
};

const getMaintenanceRedirectUrl = (state: RootState): string => {
  const maintenancePage = getMaintenancePage();
  const locale = getCurrentLocale(state);
  const lang = encodeURIComponent(getCurrentLocale(state));
  const origin = encodeURIComponent(window.location.origin);
  const appName = encodeURIComponent(getTranslations(locale)['app.name']);
  const contactEmail = encodeURIComponent(getContactUsEmail());
  return `${maintenancePage}?redirectUri=${origin}&language=${lang}&application=${appName}&contactUs=${contactEmail}`;
};

export const currentUserInfoFetchedThunk =
  (user: User): Thunk<void> =>
  dispatch => {
    dispatch(currentUserInfoFetched(user));
    trackUserInMatomo(user);
  };

export const currentUserInfoFetchFailedThunk =
  (error: DcErrorResponse): Thunk<void> =>
  (dispatch, getState) => {
    dispatch(currentUserInfoFetchFailed(error));

    const state = getState();
    const errorRedirectUrl =
      error.status === 401 || error.status === 403
        ? getUnauthorizedRedirectUrl(state)
        : getMaintenanceRedirectUrl(state);
    window.location.href = errorRedirectUrl;
  };
