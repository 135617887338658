import { combineEpics, Epic } from 'redux-observable';
import { Actions } from './actions';
import { cancellationsEpics } from './cancellations/cancellations.epics';
import { definitionsEpics } from './definitions/definitions.epics';
import { exchangesEpics } from './exchanges/exchanges.epics';
import { executionsEpics } from './executions/executions.epics';
import { filtersEpics } from './filters/filters.epics';
import { killSwitchEpics } from './killSwitch/killSwitch.epics';
import { massCancelEpics } from './massCancel/massCancel.epics';
import { ordersEpics } from './orders/orders.epics';
import { positionsEpics } from './positions/positions.epics';
import { sessionsEpics } from './sessions/sessions.epics';
import { RootState } from './state';
import { usersEpics } from './users/users.epics';

export type DcEpicDependencies = EmptyObject;
export type DcEpic = Epic<Actions, Actions, RootState, DcEpicDependencies>;

export const rootEpics = (): DcEpic =>
  combineEpics(
    definitionsEpics(),
    exchangesEpics(),
    executionsEpics(),
    filtersEpics(),
    ordersEpics(),
    positionsEpics(),
    sessionsEpics(),
    usersEpics(),
    massCancelEpics(),
    killSwitchEpics(),
    cancellationsEpics(),
  );
