import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { NavLink, Outlet } from 'react-router-dom';

const adminPaths = ['mass-cancellation', 'kill-switch'];

export const AdminOutlet = () => (
  <div className="d-flex h-100">
    <div className="align-self-stretch border-end light">
      <div className="list-group fw-semibold">
        {adminPaths.map(path => (
          <NavLink
            key={path}
            className={({ isActive }) =>
              classNames('list-group-item list-group-item-action pe-64px', { 'list-group-item-info active': isActive })
            }
            to={`/admin/${path}`}
          >
            <FormattedMessage id={`admin.${path}`} />
          </NavLink>
        ))}
      </div>
    </div>

    <div className="flex-fill p-3 overflow-auto">
      <Outlet />
    </div>
  </div>
);
