/* eslint-disable @typescript-eslint/no-explicit-any */
import { AjaxResponse } from 'rxjs/ajax';
import { sgwtConnect } from 'SgConnect';
import { HttpResponseMiddleware } from 'utils/http/httpClient.models';

export const tokenExpiredHttpResponseMiddleware: HttpResponseMiddleware = (
  response: AjaxResponse<any>,
): AjaxResponse<any> => {
  if (response.status === 401 && !sgwtConnect.isAuthorized()) {
    sgwtConnect.requestAuthorization();
  }
  return response;
};
