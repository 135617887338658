import { MASS_CANCEL_FAILED, MASS_CANCEL_REQUESTED, MASS_CANCEL_SUCCEED } from './massCancel.actions';
import { MassCancelState, initialMassCancelState } from './massCancel.model';
import { Actions } from 'store/actions';

export const massCancelReducer = (
  state: MassCancelState = initialMassCancelState,
  action: Actions,
): MassCancelState => {
  switch (action.type) {
    case MASS_CANCEL_REQUESTED:
      return { ...state, massCancelStatus: 'IN_PROGRESS', massCancelError: undefined };
    case MASS_CANCEL_SUCCEED:
      return { ...state, massCancelStatus: 'SUCCESS', massCancelError: undefined };
    case MASS_CANCEL_FAILED:
      return { ...state, massCancelStatus: 'FAIL', massCancelError: action.error };
    default:
      return state;
  }
};
