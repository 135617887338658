import { v4 as uuidv4 } from 'uuid';
import { HttpRequestMiddleware, HttpConfig } from 'utils/http/httpClient.models';

export const correlationIdHttpRequestMiddleware: HttpRequestMiddleware = (config: HttpConfig): HttpConfig => ({
  ...config,
  headers: {
    ...(config.headers ?? {}),
    'x-correlation-id': uuidv4(),
  },
});
