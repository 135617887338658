import { combineEpics, Epic, ofType } from 'redux-observable';
import { mergeMap, catchError, tap, map, of } from 'rxjs';
import { KillSwitchRequested, KILL_SWITCH_REQUESTED, killSwitchSucceed, killSwitchFailed } from './killSwitch.actions';
import { KillSwitchApi, killSwitchApiConnector } from 'api/killSwitch';
import { DcErrorResponse } from 'models/Responses/DcErrorResponse';
import { toast } from 'toast';
import { httpClient } from 'utils/http/httpClient';

const killSwitchApi$ = killSwitchApiConnector(httpClient);

export const killSwitchRequestedEpic =
  (api$: KillSwitchApi): Epic =>
  actions$ =>
    actions$.pipe(
      ofType(KILL_SWITCH_REQUESTED),
      tap(() =>
        toast.info({
          header: 'toast.kill-switch.requested.header',
          message: 'toast.kill-switch.requested.message',
        }),
      ),
      mergeMap(({ exchange, brokerId }: KillSwitchRequested) =>
        api$.killSwitch(exchange, brokerId).pipe(
          tap(() => {
            toast.clearAll();
            toast.success({
              header: 'toast.kill-switch.success.header',
              message: 'toast.kill-switch.success.message',
            });
          }),
          map(killSwitchSucceed),
          catchError((e: DcErrorResponse) => {
            toast.clearAll();

            if (e.status === 403) {
              toast.danger({
                header: 'toast.kill-switch.fail.403.header',
                message: 'toast.kill-switch.fail.403.message',
              });
            } else {
              toast.danger({
                header: 'toast.kill-switch.fail.header',
                message: 'toast.kill-switch.fail.message',
              });
            }

            return of(killSwitchFailed(e));
          }),
        ),
      ),
    );

export const killSwitchEpics = (): Epic => combineEpics(killSwitchRequestedEpic(killSwitchApi$));
