import { DcErrorResponse } from 'models/Responses/DcErrorResponse';

export type KillSwitchActions = KillSwitchRequested | KillSwitchSucceed | KillSwitchFailed;

export const KILL_SWITCH_REQUESTED = '[Kill Switch API] Kill Switch requested';
export const killSwitchRequested = (exchange: string, brokerId: string) =>
  ({ type: KILL_SWITCH_REQUESTED, exchange, brokerId } as const);
export type KillSwitchRequested = ReturnType<typeof killSwitchRequested>;

export const KILL_SWITCH_SUCCEED = '[Kill Switch API] Kill Switch succeed';
export const killSwitchSucceed = () => ({ type: KILL_SWITCH_SUCCEED } as const);
export type KillSwitchSucceed = ReturnType<typeof killSwitchSucceed>;

export const KILL_SWITCH_FAILED = '[Kill Switch API] Kill Switch failed';
export const killSwitchFailed = (error: DcErrorResponse) => ({ type: KILL_SWITCH_FAILED, error } as const);
export type KillSwitchFailed = ReturnType<typeof killSwitchFailed>;
