import { DcErrorResponse } from '../../models/Responses/DcErrorResponse';
import { ApiRequestStatus } from 'models/ApiRequestStatus';
import { Order } from 'models/Orders/Order';
import { Position } from 'models/Position';

// TODO: Refactor into state.blotters.[blotterType].{}
export interface PositionsState {
  exportError?: DcErrorResponse;
  exportStatus?: ApiRequestStatus;
  queryError?: DcErrorResponse;
  queryStatus?: ApiRequestStatus;
  results?: Position[];
  positionDetails?: Order[];
  positionDetailsQueryError?: DcErrorResponse;
  positionDetailsQueryStatus?: ApiRequestStatus;
  selectedPosition?: Partial<Position>;
}

export const initialPositionsState: PositionsState = {};
