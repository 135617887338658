import { ApiRequestStatus } from 'models/ApiRequestStatus';
import { Order } from 'models/Orders/Order';
import { DcErrorResponse } from 'models/Responses/DcErrorResponse';
import { PaginationDetails } from 'models/Responses/PagedResponse';

// TODO: Refactor into state.blotters.[blotterType].{}
export interface OrdersState {
  exportError?: DcErrorResponse;
  exportStatus?: ApiRequestStatus;
  pagination?: PaginationDetails;
  queryError?: DcErrorResponse;
  queryStatus?: ApiRequestStatus;
  results?: Order[];
  // TODO: Refactor into state.blotters.OrderDetails.{}
  selectedOrderDetails?: Order[];
  selectedOrderDetailsPagination?: PaginationDetails;
  selectedOrderDetailsQueryError?: DcErrorResponse;
  selectedOrderDetailsQueryStatus?: ApiRequestStatus;
  // TODO: Refactor into state.blotters.Orders.selectedIds
  selectedOrderIds: string[];
}

export const initialOrdersState: OrdersState = {
  exportError: undefined,
  exportStatus: undefined,
  pagination: undefined,
  queryError: undefined,
  queryStatus: undefined,
  results: undefined,
  selectedOrderDetails: undefined,
  selectedOrderDetailsPagination: undefined,
  selectedOrderDetailsQueryError: undefined,
  selectedOrderDetailsQueryStatus: undefined,
  selectedOrderIds: [],
};
