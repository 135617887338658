import { DcErrorResponse } from '../../models/Responses/DcErrorResponse';
import { RootState } from '../state';
import { OrdersState } from './orders.model';
import { Order } from 'models/Orders/Order';
import { OrderFunctionalStatus } from 'models/Orders/OrderFunctionalStatus';
import { PaginationDetails } from 'models/Responses/PagedResponse';
import { hasPermission } from 'store/users/users.selectors';
import { isRequestStatusFail, isRequestStatusInProgress } from 'utils/requests/requestStatus';

const selectOrdersState = (state: RootState): OrdersState => state.orders;

export const isOrdersQueryInProgress = (state: RootState): boolean =>
  isRequestStatusInProgress(selectOrdersState(state).queryStatus);

export const isOrdersQueryFailed = (state: RootState): boolean =>
  isRequestStatusFail(selectOrdersState(state).queryStatus);

export const getOrdersPaginationDetails = (state: RootState): PaginationDetails | undefined =>
  selectOrdersState(state).pagination;

export const getOrdersQueryResults = (state: RootState): Order[] => selectOrdersState(state).results ?? [];

export const isOrdersExportInProgress = (state: RootState): boolean =>
  isRequestStatusInProgress(selectOrdersState(state).exportStatus);

export const getSelectedOrderIds = (state: RootState): string[] => selectOrdersState(state).selectedOrderIds;

export const getSelectedOrderDetails = (state: RootState): Order[] =>
  selectOrdersState(state).selectedOrderDetails ?? [];

export const getSelectedOrderDetailsPagination = (state: RootState): PaginationDetails | undefined =>
  selectOrdersState(state).selectedOrderDetailsPagination;

export const isOrderDetailsQueryInProgress = (state: RootState): boolean =>
  isRequestStatusInProgress(selectOrdersState(state).selectedOrderDetailsQueryStatus);

export const isOrderDetailsQueryFailed = (state: RootState): boolean =>
  isRequestStatusFail(selectOrdersState(state).selectedOrderDetailsQueryStatus);

export const getOrdersQueryError = (state: RootState): DcErrorResponse | undefined =>
  selectOrdersState(state).queryError;

export const getOrderDetailsQueryError = (state: RootState): DcErrorResponse | undefined =>
  selectOrdersState(state).selectedOrderDetailsQueryError;

export const getOrdersExportError = (state: RootState): DcErrorResponse | undefined =>
  selectOrdersState(state).exportError;

export const getOrderResult = (state: RootState, orderId: string): Order | undefined =>
  state.orders.results?.find(result => orderId === result.id);

export const getOrderFunctionalStatus = (state: RootState, orderId: string): OrderFunctionalStatus | undefined => {
  const orderResult = getOrderResult(state, orderId);
  return orderResult?.functionalStatus;
};

export const canCancelSelectedOrders =
  (selectedOrderIds: string[]) =>
  (state: RootState): boolean => {
    const isAllSelectedOrderWorking = selectedOrderIds.every(orderId => {
      const functionStatus = getOrderFunctionalStatus(state, orderId);
      return functionStatus === 'Working' || functionStatus === 'Partial filled';
    });

    const hasCancelOrdersPermission = hasPermission('CANCEL_ORDERS')(state);
    return isAllSelectedOrderWorking && hasCancelOrdersPermission;
  };
