import { Actions } from '../actions';
import {
  SG_CONNECT_TOKEN_EXPIRED,
  LOCALE_CHANGED,
  THEME_CHANGED,
  THEME_INITIALIZED,
  TIMEZONE_TYPE_CHANGED,
} from './sessions.actions';
import { SessionsState, initialSessionsState } from './sessions.model';
import { patchObject } from 'utils/objects/objects';

export const sessionsReducer = (state: SessionsState = initialSessionsState, action: Actions): SessionsState => {
  switch (action.type) {
    case SG_CONNECT_TOKEN_EXPIRED:
      return patchObject(state, 'isExpired', true);
    case LOCALE_CHANGED:
      return patchObject(state, 'locale', action.locale);
    case THEME_INITIALIZED:
    case THEME_CHANGED:
      return patchObject(state, 'theme', action.theme);
    case TIMEZONE_TYPE_CHANGED:
      return patchObject(state, 'timeZoneType', action.timezoneType);
    default:
      return state;
  }
};
