import React, { useMemo } from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import { useI18n } from 'hooks/useI18n/useI18n';

export interface LoadingProps {
  type: 'spinner' | 'blinker';
  centered?: boolean;
  className?: string;
  e2e?: string;
  size?: 'sm' | 'md' | 'lg' | 'xl';
}

export const Loading: React.FC<LoadingProps> = ({ type, className, centered = true, e2e, size = 'xl' }) => {
  const intl = useI18n();

  const loader = useMemo(
    () => (
      <StyledLoader
        $content={intl.formatMessage({ id: `${type}.loading` })}
        className={classnames(`${type}`, className, {
          [`${type}-sm`]: size === 'sm',
          [`${type}-md`]: size === 'md',
          [`${type}-lg`]: size === 'lg',
          [`${type}-xl`]: size === 'xl',
        })}
        data-e2e={e2e}
      />
    ),
    [className, e2e, intl, size, type],
  );
  return centered ? <div className="h-100 d-flex align-items-center justify-content-center">{loader}</div> : loader;
};

const StyledLoader = styled.div<{ $content: string }>`
  &:before {
    content: ${props => `'${props.$content}'`};
    margin-bottom: 4px;
  }
`;
