import { ApiRequestStatus } from 'models/ApiRequestStatus';
import { DcErrorResponse } from 'models/Responses/DcErrorResponse';

export interface MassCancelState {
  massCancelStatus?: ApiRequestStatus;
  massCancelError?: DcErrorResponse;
}

export const initialMassCancelState: MassCancelState = {
  massCancelStatus: undefined,
  massCancelError: undefined,
};
