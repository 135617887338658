import { Epic, ofType, combineEpics } from 'redux-observable';
import { map, switchMap, catchError, of } from 'rxjs';
import { DcErrorResponse } from '../../models/Responses/DcErrorResponse';
import {
  definitionFetched,
  definitionFetchFailed,
  DefinitionRequested,
  DEFINITION_REQUESTED,
} from './definitions.actions';
import { DefinitionsApi, definitionsApiConnector } from 'api/definitions';
import { httpClient } from 'utils/http/httpClient';

const definitionsApi$ = definitionsApiConnector(httpClient);

export const definitionsRequestedEpic =
  (api$: DefinitionsApi): Epic =>
  actions$ =>
    actions$.pipe(
      ofType(DEFINITION_REQUESTED),
      switchMap(({ definitionType }: DefinitionRequested) =>
        api$.getDefinition(definitionType).pipe(
          map(definitions => definitionFetched(definitionType, definitions)),
          catchError((err: DcErrorResponse) => of(definitionFetchFailed(definitionType, err))),
        ),
      ),
    );

export const definitionsEpics = (): Epic => combineEpics(definitionsRequestedEpic(definitionsApi$));
