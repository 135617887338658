import { Actions } from '../actions';
import {
  CURRENT_USER_INFO_REQUESTED,
  CURRENT_USER_INFO_FETCH_FAILED,
  CURRENT_USER_INFO_FETCHED,
} from './users.actions';
import { initialUsersState, UsersState } from './users.model';
import { patchObject } from 'utils/objects/objects';

export const usersReducer = (state: UsersState = initialUsersState, action: Actions): UsersState => {
  switch (action.type) {
    case CURRENT_USER_INFO_REQUESTED:
      return patchObject(state, 'current', undefined);
    case CURRENT_USER_INFO_FETCHED:
      return patchObject(state, 'current', action.user);
    case CURRENT_USER_INFO_FETCH_FAILED:
      return patchObject(state, 'current', undefined);
    default:
      return state;
  }
};
