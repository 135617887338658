import React, { useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { getTranslations } from 'intl/translations';
import { getCurrentLocale } from 'store/sessions/sessions.selectors';

export interface AppIntlProviderProps {
  children: React.ReactNode;
}

export const AppIntlProvider: React.FC<AppIntlProviderProps> = ({ children }) => {
  const locale = useSelector(getCurrentLocale);
  const translationsForCurrentLocale = useMemo(() => getTranslations(locale), [locale]);
  return (
    <IntlProvider locale={locale} messages={translationsForCurrentLocale}>
      {children}
    </IntlProvider>
  );
};
