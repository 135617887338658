import React, { Suspense, useCallback, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { AppFooter } from 'components/layouts/AppFooter/AppFooter';
import { AppHeader } from 'components/layouts/AppHeader/AppHeader';
import { AppWidgets } from 'components/layouts/AppWidgets/AppWidgets';
import { GenericErrorMessage } from 'components/messages/GenericErrorMessage/GenericErrorMessage';
import { Loading } from 'components/shared/Loading/Loading';
import { getUserFeatures, getUserPermissions } from 'store/users/users.selectors';
import { getRouteByPath, getUnauthorizedRedirectUrl, isAuthorizedToRoute } from 'utils/route/route';

const StyledRouterDiv = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
`;

export const RootOutlet: React.FC = () => {
  const { pathname } = useLocation();
  const userPermissions = useSelector(getUserPermissions);
  const userFeatures = useSelector(getUserFeatures);

  const verifyRouteAuthorization = useCallback(() => {
    const route = getRouteByPath(pathname);
    if (!route) {return;}
    if (!isAuthorizedToRoute(route, userPermissions, userFeatures))
      {window.location.replace(getUnauthorizedRedirectUrl());}
  }, [pathname, userPermissions, userFeatures]);

  useEffect(() => {
    verifyRouteAuthorization();
  }, [pathname, verifyRouteAuthorization]);

  return (
    <div className="h-100 w-100 d-flex flex-column">
      <AppHeader />
      <ErrorBoundary FallbackComponent={GenericErrorMessage}>
        <StyledRouterDiv className="flex-fill bg-lvl2">
          <Suspense fallback={<Loading type="blinker" e2e="route-loading-blinker" />}>
            <Outlet />
          </Suspense>
        </StyledRouterDiv>
      </ErrorBoundary>
      <AppFooter />
      <AppWidgets />
    </div>
  );
};
