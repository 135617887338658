import queryString from 'query-string';
import { map, Observable } from 'rxjs';
import { FilterOptionsResponse } from 'models/Responses/FilterOptionsResponse';
import { HttpClient } from 'utils/http/httpClient.models';

export type FilterOptionType =
  | 'Products'
  | 'Exchanges'
  | 'Customers'
  | 'Accounts'
  | 'Trading-systems'
  | 'Customer-systems'
  | 'User-systems';

export interface FiltersApi {
  getFilterOptions(
    exchange: string,
    type: FilterOptionType,
    queryParams?: Record<string, string>,
  ): Observable<FilterOptionsResponse>;
}

export const filtersApiConnector = (httpClient: HttpClient): FiltersApi => ({
  getFilterOptions(
    exchange: string,
    type: FilterOptionType,
    queryParams?: Record<string, string>,
  ): Observable<FilterOptionsResponse> {
    const params = queryParams ? `?${queryString.stringify(queryParams)}` : '';
    return httpClient
      .getJSON<FilterOptionsResponse>({ url: `/api/v1/${exchange}/filters/${type.toLowerCase()}${params}` })
      .pipe(map(({ payload }) => payload));
  },
});
