import React, { MouseEvent, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal, ModalFooter } from 'reactstrap';
import { I18nMessage } from 'components/i18n/I18nMessage/I18nMessage';
import { sgwtConnect } from 'SgConnect';
import { isSgConnectSessionExpired } from 'store/sessions/sessions.selectors';

export const SessionExpiredModal: React.FC = () => {
  const isSessionExpired = useSelector(isSgConnectSessionExpired);

  const onSignInAgainClicked = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    sgwtConnect.discardAuthorization();
    sgwtConnect.requestAuthorization();
  }, []);

  return (
    <Modal isOpen={isSessionExpired} contentClassName="shadow-max border border-warning" centered>
      <div className="modal-header">
        <div className="d-flex flex-column w-100">
          <div
            className="rounded-circle bg-warning bg-opacity-10 d-flex align-items-center justify-content-center mb-4"
            style={{ width: '3rem', height: '3rem' }}
          >
            <em className="icon icon-md text-warning line-height-1">update</em>
          </div>

          <h3 className="modal-title text-warning mb-2">
            <I18nMessage id="modal.sessionExpired.title" />
          </h3>

          <p className="text-large text-secondary fw-normal m-0">
            <I18nMessage id="modal.sessionExpired.message" />
          </p>
        </div>
      </div>

      <ModalFooter>
        <Button className="sgbs-btn-default" size="lg" onClick={onSignInAgainClicked}>
          <I18nMessage id="modal.sessionExpired.action.signInAgain" />
        </Button>
      </ModalFooter>
    </Modal>
  );
};
