import { Order } from 'models/Orders/Order';
import { Position } from 'models/Position';
import { QueryFilters } from 'models/QueryFilters';
import { DataResponse } from 'models/Responses/DataResponse';
import { DcErrorResponse } from 'models/Responses/DcErrorResponse';
import { PagedResponse } from 'models/Responses/PagedResponse';

export type PositionsActions =
  | PositionsQueryRequested
  | PositionsQueried
  | PositionsQueryFailed
  | PositionSelected
  | PositionDetailsRequested
  | PositionDetailsFetched
  | PositionDetailsFetchFailed;

export const POSITIONS_QUERY_REQUESTED = '[Positions API] Query requested';
export const positionsQueryRequested = (exchange: string, filters: QueryFilters) =>
  ({ type: POSITIONS_QUERY_REQUESTED, exchange, filters } as const);
export type PositionsQueryRequested = ReturnType<typeof positionsQueryRequested>;

export const POSITIONS_QUERIED = '[Positions API] Queried';
export const positionsQueried = (response: DataResponse<Position>) => ({ type: POSITIONS_QUERIED, response } as const);
export type PositionsQueried = ReturnType<typeof positionsQueried>;

export const POSITIONS_QUERY_FAILED = '[Positions API] Query failed';
export const positionsQueryFailed = (error: DcErrorResponse) => ({ type: POSITIONS_QUERY_FAILED, error } as const);
export type PositionsQueryFailed = ReturnType<typeof positionsQueryFailed>;

export const POSITION_SELECTED = '[Positions page] Position selected';
export const positionSelected = (position: Partial<Position>) => ({ type: POSITION_SELECTED, position } as const);
export type PositionSelected = ReturnType<typeof positionSelected>;

export const POSITION_DETAILS_REQUESTED = '[Positions API] Position details requested';
export const positionDetailsRequested = (exchange: string, id: string) =>
  ({ type: POSITION_DETAILS_REQUESTED, exchange, id } as const);
export type PositionDetailsRequested = ReturnType<typeof positionDetailsRequested>;

export const POSITION_DETAILS_FETCHED = '[Positions API] Position details fetched';
export const positionDetailsFetched = (details: PagedResponse<Order>) =>
  ({ type: POSITION_DETAILS_FETCHED, details } as const);
export type PositionDetailsFetched = ReturnType<typeof positionDetailsFetched>;

export const POSITION_DETAILS_FETCH_FAILED = '[Positions API] Position details fetch failed';
export const positionDetailsFetchFailed = (error: DcErrorResponse) =>
  ({ type: POSITION_DETAILS_FETCH_FAILED, error } as const);
export type PositionDetailsFetchFailed = ReturnType<typeof positionDetailsFetchFailed>;
