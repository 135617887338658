import { CustomerCode, CustomerSystemId } from 'models/Customer';
import { ExchangeMic } from 'models/ExchangeMic';
import { FilterOptionsResponse } from 'models/Responses/FilterOptionsResponse';
import { TradingSystemCode } from 'models/TradingSystemCode';

export interface FiltersState {
  products: Record<ExchangeMic, FilterOptionsResponse | undefined>;
  customers: Record<ExchangeMic, FilterOptionsResponse | undefined>;
  tradingSystems: Record<ExchangeMic, Record<CustomerCode, FilterOptionsResponse | undefined>>;
  customerSystems: Record<
    ExchangeMic,
    Record<CustomerCode, Record<TradingSystemCode, FilterOptionsResponse | undefined>>
  >;
  userSystems: Record<
    ExchangeMic,
    Record<CustomerCode, Record<TradingSystemCode, Record<CustomerSystemId, FilterOptionsResponse | undefined>>>
  >;
}

export const initialFiltersState: FiltersState = {
  products: {},
  customers: {},
  tradingSystems: {},
  customerSystems: {},
  userSystems: {},
};
