import React, { useMemo } from 'react';
import { useAppConfigs } from 'hooks/useAppConfigs/useAppConfigs';

export const AppFooter: React.FC = () => {
  const { contactUsEmail, privacyPolicyUrl } = useAppConfigs();
  const contactUsConfig = useMemo(() => `{'mail': '${contactUsEmail}'}`, [contactUsEmail]);
  const privacyPolicyConfig = useMemo(() => `{'en': [ {'label': 'DROP COPY privacy policy', 'value': '${privacyPolicyUrl}', 'openInSamePage': false } ]}`, [privacyPolicyUrl]);

  return (
    <footer>
      <sgwt-mini-footer
        contact-us-by-help-center="#sgm-dropcopy-help-center"
        contact-us={contactUsConfig}
        legal-notices={privacyPolicyConfig}
        mode="sg-markets"
        id="sgm-dropcopy-mini-footer"
      />
    </footer>
  );
};
