import { DcErrorResponse } from '../../models/Responses/DcErrorResponse';
import { ApiRequestStatus } from 'models/ApiRequestStatus';
import { Definition, DefinitionType } from 'models/Definitions';

export interface DefinitionState {
  definition?: Definition;
  definitionFetchError?: DcErrorResponse;
  definitionFetchStatus?: ApiRequestStatus;
}

export type DefinitionsState = Record<DefinitionType, DefinitionState>;

export const initialDefinitionsState: DefinitionsState = {
  orders: {},
  executions: {},
  orderDetails: {},
  positions: {},
};
