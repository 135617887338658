import { DcErrorResponse } from '../../models/Responses/DcErrorResponse';
import { ApiRequestStatus } from 'models/ApiRequestStatus';
import { Order } from 'models/Orders/Order';
import { PaginationDetails } from 'models/Responses/PagedResponse';

// TODO: Refactor into state.blotters.[blotterType].{}
export interface ExecutionsState {
  exportError?: DcErrorResponse;
  exportStatus?: ApiRequestStatus;
  pagination?: PaginationDetails;
  queryError?: DcErrorResponse;
  queryStatus?: ApiRequestStatus;
  results?: Order[];
}

export const initialExecutionsState: ExecutionsState = {
  exportError: undefined,
  exportStatus: undefined,
  pagination: undefined,
  queryError: undefined,
  queryStatus: undefined,
  results: undefined,
};
