import { SGWTConnectCore } from '@sgwt/connect-core';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { interval, SchedulerLike, asyncScheduler, map, filter, tap, ignoreElements } from 'rxjs';
import {
  sgConnectTokenExpired,
  THEME_CHANGED,
  ThemeChanged,
  TIMEZONE_TYPE_CHANGED,
  TimezoneTypeChanged,
} from './sessions.actions';
import { LocalStorageApi, localStorageApi } from 'api/localStorage';
import { sgwtConnect } from 'SgConnect';
import { applySgBootstrapTheme } from 'theme';

export const sessionsExpiredEpic =
  (sgConnect: SGWTConnectCore, scheduler: SchedulerLike): Epic =>
  () =>
    interval(1000, scheduler).pipe(
      filter(() => !sgConnect.isAuthorized()),
      map(() => sgConnectTokenExpired()),
    );

export const themeChangedEpic =
  (api: LocalStorageApi): Epic =>
  actions$ =>
    actions$.pipe(
      ofType(THEME_CHANGED),
      tap(({ theme }: ThemeChanged) => {
        api.set('APP_THEME', theme);
        applySgBootstrapTheme(theme);
      }),
      ignoreElements(),
    );

export const timezoneChangedEpic =
  (api: LocalStorageApi): Epic =>
  actions$ =>
    actions$.pipe(
      ofType(TIMEZONE_TYPE_CHANGED),
      tap(({ timezoneType }: TimezoneTypeChanged) => {
        api.set('APP_TIMEZONE_TYPE', timezoneType);
      }),
      ignoreElements(),
    );

export const sessionsEpics = (): Epic =>
  combineEpics(
    sessionsExpiredEpic(sgwtConnect, asyncScheduler),
    themeChangedEpic(localStorageApi),
    timezoneChangedEpic(localStorageApi),
  );
