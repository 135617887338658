import { Exchange } from 'models/Exchange';
import { ExchangeInfo } from 'models/ExchangeInfo';
import { DcErrorResponse } from 'models/Responses/DcErrorResponse';

export type ExchangesActions =
  | ExchangesDefinitionsRequested
  | ExchangesDefinitionsFetched
  | ExchangesDefinitionsFetchFailed
  | ExchangesInfoRequested
  | ExchangesInfoFetched
  | ExchangesInfoFetchFailed;

export const EXCHANGES_DEFINITIONS_REQUESTED = '[Exchanges API] Definition requested';
export const exchangesDefinitionsRequested = () => ({ type: EXCHANGES_DEFINITIONS_REQUESTED } as const);
export type ExchangesDefinitionsRequested = ReturnType<typeof exchangesDefinitionsRequested>;

export const EXCHANGES_DEFINITIONS_FETCHED = '[Exchanges API] Definition fetched';
export const exchangesDefinitionsFetched = (definitions: Exchange[]) =>
  ({ type: EXCHANGES_DEFINITIONS_FETCHED, definitions } as const);
export type ExchangesDefinitionsFetched = ReturnType<typeof exchangesDefinitionsFetched>;

export const EXCHANGES_DEFINITIONS_FETCH_FAILED = '[Exchanges API] Definition fetch failed';
export const exchangesDefinitionsFetchFailed = (error: DcErrorResponse) =>
  ({ type: EXCHANGES_DEFINITIONS_FETCH_FAILED, error } as const);
export type ExchangesDefinitionsFetchFailed = ReturnType<typeof exchangesDefinitionsFetchFailed>;

export const EXCHANGES_INFO_REQUESTED = '[Exchanges API] Info requested';
export const exchangesInfoRequested = () => ({ type: EXCHANGES_INFO_REQUESTED } as const);
export type ExchangesInfoRequested = ReturnType<typeof exchangesInfoRequested>;

export const EXCHANGES_INFO_FETCHED = '[Exchanges API] Info fetched';
export const exchangesInfoFetched = (exchangesInfo: ExchangeInfo[]) =>
  ({ type: EXCHANGES_INFO_FETCHED, exchangesInfo } as const);
export type ExchangesInfoFetched = ReturnType<typeof exchangesInfoFetched>;

export const EXCHANGES_INFO_FETCH_FAILED = '[Exchanges API] Info fetch failed';
export const exchangesInfoFetchFailed = (error: DcErrorResponse) =>
  ({ type: EXCHANGES_INFO_FETCH_FAILED, error } as const);
export type ExchangesInfoFetchFailed = ReturnType<typeof exchangesInfoFetchFailed>;
