import { combineEpics, Epic, ofType } from 'redux-observable';
import { catchError, switchMap, map, of, EMPTY, interval, mergeMap, takeUntil } from 'rxjs';
import {
  CANCEL_ORDERS_BATCH_INITIALIZED,
  CANCEL_ORDERS_INIT_REQUESTED,
  CANCEL_ORDERS_STOP_REQUESTED,
  CancelOrdersBatchInitRequested,
  cancelOrdersBatchInitFailed,
  cancelOrdersBatchInitialized,
} from './cancellations.actions';
import { cancelOrdersBatchUpdatedThunk } from './cancellations.thunks';
import { CancellationsApi, CancellationsApiConnector } from 'api/cancellations';
import { CancellationBatchResponse } from 'models/Cancellations/CancellationBatchResponse';
import { DcErrorResponse } from 'models/Responses/DcErrorResponse';
import { httpClient } from 'utils/http/httpClient';
import { isDefinedAndNotEmpty } from 'utils/string/string';

export const { cancellationStatus: cancellationStatusPollingInterval } = window.appConfigs.pollingInterval;
const cancellationsApi$ = CancellationsApiConnector(httpClient);

export const cancelOrdersBatchRequestedEpic =
  (api$: CancellationsApi): Epic =>
  actions$ =>
    actions$.pipe(
      ofType(CANCEL_ORDERS_INIT_REQUESTED),
      switchMap(({ exchange, orderIds }: CancelOrdersBatchInitRequested) =>
        api$.cancelOrdersBatch(exchange, orderIds).pipe(
          map((response: CancellationBatchResponse) => cancelOrdersBatchInitialized(exchange, response)),
          catchError((err: DcErrorResponse) => of(cancelOrdersBatchInitFailed(err))),
        ),
      ),
    );

export const pollBatchCancellationStatusEpic =
  (api$: CancellationsApi): Epic =>
  actions$ =>
    actions$.pipe(
      ofType(CANCEL_ORDERS_BATCH_INITIALIZED),
      mergeMap(({ cancelBatch, exchange }) => {
        const cancellationId = cancelBatch.id;

        if (!isDefinedAndNotEmpty(cancellationId)) {
          return EMPTY;
        }

        const stopPolling$ = actions$.pipe(ofType(CANCEL_ORDERS_STOP_REQUESTED));

        return interval(cancellationStatusPollingInterval ?? 5000).pipe(
          takeUntil(stopPolling$),
          switchMap(() =>
            api$.getCancellationBatch(exchange, cancellationId).pipe(
              map(batchResponse => cancelOrdersBatchUpdatedThunk(exchange, batchResponse)),
              catchError(() => EMPTY),
            ),
          ),
        );
      }),
    );

export const cancellationsEpics = (): Epic =>
  combineEpics(cancelOrdersBatchRequestedEpic(cancellationsApi$), pollBatchCancellationStatusEpic(cancellationsApi$));
