import { AjaxError, AjaxResponse } from 'rxjs/ajax';
import { HttpConfig, HttpGetConfig, HttpRequestMiddleware, HttpResponseMiddleware } from '../httpClient.models';
import { correlationIdHttpRequestMiddleware } from './correlationId/correlationId';
import { sgConnectHttpRequestMiddleware } from './sgConnect/sgConnect';
import { tokenExpiredHttpResponseMiddleware } from './tokenExpired/tokenExpired';
import { webApiBaseUrlHttpRequestMiddleware } from './webApiBaseUrl/webApiBaseUrl';

const baseMiddleware: HttpRequestMiddleware[] = [
  sgConnectHttpRequestMiddleware,
  correlationIdHttpRequestMiddleware,
  webApiBaseUrlHttpRequestMiddleware,
];
const baseGetMiddleware: HttpRequestMiddleware[] = [...baseMiddleware];

const baseErrorResponseMiddleware: HttpResponseMiddleware[] = [tokenExpiredHttpResponseMiddleware];

export function applyRequestMiddleware(config: HttpConfig): HttpConfig {
  return baseMiddleware.reduce<HttpConfig>((currentConfig, m) => m(currentConfig), config);
}

export function applyGetRequestMiddleware(config: HttpGetConfig): HttpGetConfig {
  return baseGetMiddleware.reduce<HttpGetConfig>((currentConfig, m) => m(currentConfig), config);
}

export const applyResponseMiddleware = <T>(response: AjaxResponse<T>): AjaxResponse<T> => response;

export const applyResponseErrorMiddleware = (error: AjaxError): AjaxError =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  baseErrorResponseMiddleware.reduce<any>((currentResponse, m) => m(currentResponse), error);
