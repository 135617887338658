import { map, Observable } from 'rxjs';
import { getPagedQueryString } from './helpers/queryParams.helpers';
import { ExportContext } from 'models/ExportContext';
import { Order } from 'models/Orders/Order';
import { OrderSide } from 'models/Orders/OrderSide';
import { QueryFilters } from 'models/QueryFilters';
import { PagedResponse } from 'models/Responses/PagedResponse';
import { getAttachmentFilename } from 'utils/http/httpClient.helpers';
import { HttpClient } from 'utils/http/httpClient.models';

export type OrdersApi = OrdersApiBase & {
  getDetails(exchange: string, orderId: string, page: number, size: number): Observable<PagedResponse<Order>>;
  cancelOrder(exchange: string, exchangeOrderId: string): Observable<Record<string, never>>;
  cancelOrderNext(
    exchange: string,
    exchangeOrderId: string,
    orderSide: OrderSide,
    passingCode: string,
  ): Observable<Record<string, never>>;
};

export interface OrdersApiBase {
  query(exchange: string, page: number, size: number, filters: QueryFilters): Observable<PagedResponse<Order>>;
  export(exchange: string, filters: QueryFilters): Observable<ExportContext>;
}

export const ordersApiBaseConnector = (httpClient: HttpClient, endpoint: string): OrdersApiBase => ({
  query(exchange: string, page: number, size: number, filters: QueryFilters): Observable<PagedResponse<Order>> {
    const qs = getPagedQueryString(page, size, filters);
    return httpClient
      .getJSON<PagedResponse<Order>>({ url: `/api/v1/${exchange}/${endpoint}?${qs}` })
      .pipe(map(({ payload }) => payload));
  },
  export(exchange: string, filters: QueryFilters): Observable<ExportContext> {
    const qs = getPagedQueryString(0, 50000, filters);
    return httpClient.getBlob({ url: `/api/v1/${exchange}/${endpoint}/_csv?${qs}` }).pipe(
      map(({ payload, headers }) => {
        const filename = getAttachmentFilename(headers) ?? 'Export.csv';
        return { filename, payload };
      }),
    );
  },
});

export const ordersApiConnector = (httpClient: HttpClient): OrdersApi => ({
  ...ordersApiBaseConnector(httpClient, 'orders'),
  getDetails(exchange: string, orderId: string, page: number, size: number): Observable<PagedResponse<Order>> {
    return httpClient
      .getJSON<PagedResponse<Order>>({
        url: `/api/v1/${exchange}/orders/${orderId}/details?page=${page}&size=${size}`,
      })
      .pipe(map(({ payload }) => payload));
  },
  // TODO: Unit test
  cancelOrder(exchange: string, exchangeOrderId: string): Observable<Record<string, never>> {
    return httpClient
      .post<Record<string, never>>({ url: `/api/v1/${exchange}/orders/${exchangeOrderId}/do-cancel` })
      .pipe(map(({ payload }) => payload));
  },
  cancelOrderNext(
    exchange: string,
    orderId: string,
    orderSide: OrderSide,
    passingCode: string,
  ): Observable<Record<string, never>> {
    return httpClient
      .post<Record<string, never>>({
        url: `/api/v1/${exchange}/orders/do-cancel`,
        body: { orderId, orderSide, passingCode },
      })
      .pipe(map(({ payload }) => payload));
  },
});
