import React, { useMemo, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppConfigs } from 'hooks/useAppConfigs/useAppConfigs';
import { Locale } from 'intl/translations';
import { localeChanged, themeChanged } from 'store/sessions/sessions.actions';
import { getCurrentLocale } from 'store/sessions/sessions.selectors';

const signOutEventHandler = (): void => {
  window.location.href = window.appConfigs.signOutUrl;
};

export const SgwtAccountCenter: React.FC = () => {
  const {
    availableLanguages,
    environment: { label: envLabel },
    sgwtServices: { partialIncidentProducerCode },
  } = useAppConfigs();
  const dispatch = useDispatch();

  const lang = useSelector(getCurrentLocale);
  const availableLanguagesConfig = useMemo(() => availableLanguages.join(','), [availableLanguages]);

  const languageChangedEventHandler = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event: any) => {
      dispatch(localeChanged((event as CustomEvent).detail.language as Locale));
    },
    [dispatch],
  );

  const themeChangedEventHandler = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event: any) => {
      const sgwtTheme = event.detail.theme;
      const newBootstrapTheme = sgwtTheme === 'dark' ? 'DARK' : 'STANDARD';
      dispatch(themeChanged(newBootstrapTheme));
    },
    [dispatch],
  );

  const accountCenter = useRef<Element>();
  useEffect(() => {
    const ref = accountCenter.current;
    if (ref !== undefined) {
      ref.addEventListener('sgwt-account-center--sign-out', signOutEventHandler);
      ref.addEventListener('sgwt-account-center--language-changed', languageChangedEventHandler);
      ref.addEventListener('sgwt-account-center--theme-changed', themeChangedEventHandler);
    }
    return () => {
      if (ref !== undefined) {
        ref.removeEventListener('sgwt-account-center--sign-out', signOutEventHandler);
        ref.removeEventListener('sgwt-account-center--language-changed', languageChangedEventHandler);
        ref.addEventListener('sgwt-account-center--theme-changed', themeChangedEventHandler);
      }
    };
  }, [accountCenter, languageChangedEventHandler, themeChangedEventHandler]);

  return (
    <sgwt-account-center
      // show-personalisation="true"
      authentication="sg-connect-v2"
      available-languages={availableLanguagesConfig}
      environment={envLabel}
      id="sgm-dropcopy-account-center"
      language={lang}
      mode="sg-markets"
      ref={accountCenter}
      producer-code={partialIncidentProducerCode}
      theme-switcher="true"
    />
  );
};
